import { useQuery, useQueryClient } from "react-query"
import { getDatos } from "../../providers/dataServer"

const useGetFlowResponses = () => {

    const queryClient = useQueryClient()
    const servicio = queryClient.getQueriesData('services')[0][1][0]
    const idBase64 = btoa(servicio.id.toString())

    const cacheFlowsList = queryClient.getQueryData(["respuestas"]);
    let isUndefined = false;
    //console.log(cacheFlowsList)

    const { data: { flows: flowsList } = { flows: [] }, isLoading, refetch } = useQuery(["respuestas"], () => getDatos('/bot-whatsapp/getRespuestas', { s: idBase64 }), {
        enabled: false,
    })

    if (typeof cacheFlowsList === 'undefined') {
        isUndefined = true
        refetch()
    }

    const getFlowItem = (value, flowsList) => {
        try {
            const numero = value.match(/^\d+/)[0];
            const startIndex = value.indexOf("_") + 1;
            const extractedPart = value.slice(startIndex);

            const flow = flowsList.find((flow) => flow.id === parseInt(numero) && flow.tipoRespuesta === extractedPart)
            return flow ? flow : null
        } catch (error) {
            return null
        }
    }

    return isUndefined ? {
        isLoadingFlows: isLoading,
        flowsList: flowsList,
        getFlowItem: (value, flowsList) => getFlowItem(value, flowsList)
    } : {
        isLoadingFlows: false,
        flowsList: cacheFlowsList.flows,
        getFlowItem: (value, flowsList) => getFlowItem(value, flowsList)
    }

}

export default useGetFlowResponses