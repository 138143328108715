import QRCode from 'react-qr-code'
import styles from '../../assets/css/preview-messages.module.css'
import Icons from '../../components/Icons'
import useDecodeMessage, { useDecodeMessageById } from '../../hooks/bot/useDecodeMessage'
import Vistos from '../Vistos'
import MediaMsgItem from './MediaMsgItem'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const BeforeAfterMsg = ({ agente, id, showList, ag }) => {
    const message = useDecodeMessageById(id)
    const msg = useDecodeMessage(message)
    return <ContentMsgItem msg={msg} agente={agente} showList={showList} ag={ag} />
}

const ContentMsgItem = ({ msg, agente, showList, ag = '' }) => {

    const MySwal = withReactContent(Swal)

    function whatsappToHtml(text) {
        try {
           // Reemplaza los asteriscos por <strong> (negrita)
            const boldRegex = /\*(.*?)\*/g;
            const htmlText = text.replace(boldRegex, '<strong style="font-size: 12px">$1</strong>');

            // Reemplaza los guiones bajos por <em> (cursiva)
            const italicRegex = /_(.*?)_/g;
            const finalHtml = htmlText.replace(italicRegex, '<em style="font-size: 12px">$1</em>');

            // Envuelve todo el texto en una etiqueta <p>
            return finalHtml; 
        } catch (error) {
            return text
        }
        
    }

    function convertToVCard(contacto) {
        let vCard = "BEGIN:VCARD\nVERSION:3.0\n";

        // Validar el nombre
        const nombre = contacto?.name;
        if (nombre) {
            const firstName = nombre.first_name || "";
            const lastName = nombre.last_name || "";
            const formattedName = nombre.formatted_name || `${firstName} ${lastName}`.trim();
            if (formattedName) vCard += `FN:${formattedName}\n`;
            if (firstName || lastName) vCard += `N:${lastName};${firstName};;;\n`;
        }

        // Validar el email
        const email = contacto?.emails?.[0]?.email;
        if (email) vCard += `EMAIL;TYPE=INTERNET:${email}\n`;

        // Validar el teléfono
        const telefono = contacto?.phones?.[0]?.phone;
        const waId = contacto?.phones?.[0]?.wa_id;
        if (telefono) vCard += `TEL;TYPE=CELL:${telefono}\n`;
        if (waId) vCard += `X-WA-ID:${waId}\n`;

        // Cerrar la vCard
        vCard += "END:VCARD";

        return vCard;
    }

    function downloadVCard(contact) {
        const vCardData = convertToVCard(contact);
        const blob = new Blob([vCardData], { type: 'text/vcard' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download
            = `${contact.name.formatted_name}.vcf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function showQrContact(contact) {
        const vCardData = convertToVCard(contact);
        MySwal.fire({
            title: <p>Escanear Contacto</p>,
            html: <QRCode
                size={100}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={vCardData}
                viewBox={`0 0 256 256`}
            />
        })
    }

    return <div className={styles.contMessageItem}>
        {ag !== '' && <p className={styles.afMessage}>{ag}</p>}
        <div className={`${styles.contMessage} ${agente === 'Bot' ? styles.myMessage : ''}`}>
            <MediaMsgItem msgType={msg.tipo} mediaItem={msg.media} />
            {msg.tipo === 'contacts' && <div className={styles.contactItemMsg}>
                <div className={styles.ctIconMsg}>
                    <Icons req={'user'} height='1.5em' />
                </div>
                <div className={styles.contContactInfo}>
                    <p>{msg.contact.name.formatted_name}</p>
                    <span>{msg.contact.phones[0].phone}</span>
                </div>
            </div>}
            <div className={styles.textMessage} dangerouslySetInnerHTML={{ __html: whatsappToHtml(msg.texto) }} />
            <div className={styles.messageDateCheck}>
                <span className={styles.date}>{msg.fecha.hora}</span>
                <Vistos estado={msg.estado} />
            </div>
        </div>
        {(msg.tipo === 'button' || msg.tipo === 'list') &&
            Object.values(msg?.buttons ?? []).map(b => <div key={b.id} className={`${styles.buttonsMessage} ${agente === 'Bot' ? styles.myMessage : ''}`}>
                {msg.tipo === 'button' ?
                    <p className={styles.buttonItem}>{b.text}</p> :
                    <p className={styles.buttonItem} onClick={() => showList(msg.list)}><Icons req={'list'} fill="rgb(0, 157, 226)" />{b.text}</p>
                }
            </div>)
        }
        {msg.tipo === 'contacts' &&
            <div className={`${styles.buttonsContacts} ${agente === 'Bot' ? styles.myMessage : ''}`}>
                <button onClick={() => downloadVCard(msg.contact)} >Descargar vCard</button>
                <button onClick={() => showQrContact(msg.contact)} >Escanear</button>
            </div>
        }
    </div>
}

const FormContenItem = ({ agent = 'Bot', flow, showList }) => {
    const msg = useDecodeMessage(flow, true)
    return <ContentMsgItem msg={msg} agente={agent} showList={showList} ag={ flow.ag ?? '' }/>
}

const PreviewmessageItem = ({ agent = 'Bot', message, showList }) => {

    const msg = useDecodeMessage(message)
    const agente = message?.asesor ?? agent
    const boolF = message?.tipoRespuesta ?? 'flow'
    const isForm = boolF === 'form'

    let formsItems = []
    if (isForm) {
        if (typeof message.flow !== 'undefined') {
            formsItems = JSON.parse(message.flow)
        }
        if (typeof message.flow_final !== 'undefined') {
            formsItems.push({... JSON.parse(message.flow_final), ag: "Respuesta final"})
        }
        if (typeof message.flow_final_correcto !== 'undefined') {
            formsItems.push({... JSON.parse(message.flow_final_correcto), ag: "Respuesta final si es correcto"})
        }
        if (typeof message.flow_final_incorrecto !== 'undefined') {
            formsItems.push({... JSON.parse(message.flow_final_incorrecto), ag: "Respuesta final si es incorrecto"})
        }
    }

    return isForm ? <>
        {
            formsItems?.map((formItem, index) => <FormContenItem key={index} flow={formItem} />)
        }
    </> : <>
        {msg?.before > 0 && <BeforeAfterMsg id={msg?.before} agente={agente} showList={showList} ag='Pre mensaje' />}
        <ContentMsgItem msg={msg} agente={agente} showList={showList} />
        {msg?.after > 0 && <BeforeAfterMsg id={msg?.after} agente={agente} showList={showList} ag='Pos mensaje' />}
    </>
}

export default PreviewmessageItem