import styles from '../../assets/css/preview-messages.module.css'
import { urlLocalResource } from '../../providers/serverProvider'
import imagenDefault from '../../assets/images/no-image.jpg'

const MediaMsgItem = ({ msgType, mediaItem }) => {

    if ((mediaItem.url === '' && mediaItem.name  === '') || (mediaItem.url === undefined && mediaItem.name  === undefined)) {
        return <></>
    }

    const imageUrl = mediaItem.url !== '' ? mediaItem.url : (mediaItem.name !== '' ? urlLocalResource(mediaItem.name) : imagenDefault)
    let media = <></>

    switch(msgType) {
        /*case 'sticker':
            media = <Sticker img={ file }/>
            break*/
        case 'image':
            media = <img src={ imageUrl } className={ styles.imagenMgsItem } />
            break
        /*case 'audio':
            media = <Audio media={ file }/>
            break
        case 'video':
            media = <Video video={ file }/>
            break
        case 'document':
            media = <Document file={ file } nombre={ msg.nombre_archivo }/>
            break
        case 'location':
            let location = JSON.parse(msg.location)
            media = <Location latitude={ location.latitude } longitude={ location.longitude } />
            break
        case 'contacts':
            media = <Contacts cname={ msg.cname } cnumero={ msg.cnumero } contact={ !!msg.contacto ? msg.contacto : '{}' } />
            break*/
        case 'button':
            media = <img src={ imageUrl } className={ styles.imagenMgsItem } />
            /*if (med.type === 'video') {
                media = <Video video={ file }/>
            }
            break*/
        /*case 'list':
            //console.log(mensaje)
            break*/
        default:
    }

    return <>
        { media }
    </>
}

export default MediaMsgItem