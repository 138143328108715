import { useFormContext } from 'react-hook-form';
import styles from '../../assets/css/formularios.module.css';

export default function FormularioItemFin({ type }) {

    const { register, formState: { errors } } = useFormContext()
    const steepName = `respuestaFinal${type}`

    const mapTipoShow = {
        'text': 'Texto',
        'buttons': 'Botones'
    }

    const isValidImageUrl = async (url) => {
        try {
            const response = await fetch(url, { method: 'HEAD' });
            const contentType = response.headers.get('Content-Type');
            return response.ok && contentType.startsWith('image/');
        } catch (error) {
            return false;
        }
    };

    return <div className={styles.contFormularioSteep}>
        <div className={styles.formItemSteepHeader}>
            <div className={styles.titleSteep}>
                <div className={styles.number}>{'FIN'}</div>
                <p>Respuesta Final {type}</p>
            </div>
            <div className={styles.typeSteep}>
                <div className={styles.typeSteepLine}></div>
                <p>{mapTipoShow['text']}</p>
            </div>
        </div>
        <div className={styles.formItemSteepBody}>
            <div className={`${styles.formItem} ${styles.formItem_100}`} style={{ marginBottom: 15 }}>
                <input type='text' placeholder="Url imagen (Opcional)" {...register(`${steepName}-image`, {
                    validate: async value => value !== '' ? (await isValidImageUrl(value) || 'Este enlace no esta soportado') : true
                })} />
                <span className={styles.errorForm}>{!!errors[`${steepName}-image`] && errors[`${steepName}-image`].message}</span>
            </div>
            <div className={styles.formItem_100}>
                <textarea placeholder="Escribe el requerimiento o pregunta" {...register(`${steepName}-text`, {
                    required: {
                        value: true,
                        message: "Escribe la respuesta final"
                    },
                })} rows={6}></textarea>
                <span className={styles.errorForm}>{!!errors[`${steepName}-text`] && errors[`${steepName}-text`].message}</span>
            </div>
            <p className={styles.formItemDescripcionText}>Esta es la respuesta con la que se termina el formulario si es: {type}</p>
        </div>
    </div>
}