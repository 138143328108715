import Spiner from "../components/Spiner";
import { getDatos, mutarDatos } from "../providers/dataServer";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { customSwall } from "../components/CustomSwal";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Icons from "../components/Icons";
import useGetFlowResponses from "../hooks/bot/useGetFlowResponses";
import ModalPrevFlows from "./components/ModalPrevFlows";
import { Button, ButtonToolbar, IconButton, Modal, SelectPicker, Stack, TagInput } from "rsuite";
import Eye from '@rsuite/icons/legacy/Eye';
import HeaderSection from "../components/HeaderSection";

function ElementActivadorConfig({ id, cerrar, defaultValues }) {

    const queryClient = useQueryClient()
    const servicio = queryClient.getQueriesData('services')[0][1][0]
    const idBase64 = btoa(servicio.id.toString())
    const [flowSelected, setFlowSelected] = useState(null)
    const [mt, setMt] = useState(false);

    const { flowsList } = useGetFlowResponses()

    const metods = useForm({
        defaultValues: {
            id: id ?? 0,
            palabras: defaultValues.palabras ? defaultValues.palabras : [],
            type: defaultValues.type ?? 2, // 2 = texto, 1 = plantilla
            respuesta: (defaultValues.respuestaId && defaultValues.tipoRespuesta) ? `${defaultValues.respuestaId}_${defaultValues.tipoRespuesta}` : null
        },
    })
    const { formState: { errors } } = metods;

    const { mutate: guardar, isLoading: guardando } = useMutation({
        mutationFn: (datos) => mutarDatos({ ruta: '/bot-whatsapp/guardarActivadores', parametros: datos })
    })

    function cerrarPrew() {
        setFlowSelected(null)
        setMt(false)
    }

    function mostrarPrew() {
        if (flowSelected) {
            setFlowSelected(flowSelected)
        } else {
            getFlowItem(metods.watch('respuesta'))
        }
        setMt(true)
    }

    function onSubmit(datos) {
        console.log(datos)
        datos['s'] = idBase64
        datos['palabras'] = JSON.stringify(datos.palabras)
        guardar(datos, {
            onSuccess: (data) => {
                if (data.ok) {
                    queryClient.invalidateQueries(["activadores"])
                    cerrar()
                } else {
                    customSwall.fire({
                        icon: 'error',
                        title: 'Error',
                        text: !!data.error ? data.error : 'no se pudo guardar',
                        showConfirmButton: true,
                    })
                }
            },
        })
    }

    function getFlowItem(value) {
        const numero = value.match(/^\d+/)[0];
        const startIndex = value.indexOf("_") + 1;
        const extractedPart = value.slice(startIndex);

        const flow = flowsList.find((flow) => flow.id === parseInt(numero) && flow.tipoRespuesta === extractedPart)
        setFlowSelected(flow ? flow : {})
        return flow ? flow : {}
    }

    console.log(metods.watch('respuesta'))

    return <form onSubmit={metods.handleSubmit(onSubmit)}>
        {mt && <ModalPrevFlows flows={[flowSelected]} cerrar={cerrarPrew} style={{ top: '120%' }} />}
        {guardando && <Spiner />}
        <div className="cont-text-form">
            <label htmlFor="palabras">{`Lista de palabras (escribe y presiona ENTER)`}:</label>
            <Controller
                name="palabras"
                rules={{
                    required: {
                        value: true,
                        message: "Debes colocar por lo menos una palabra",
                    }
                }}
                control={metods.control}
                render={({ field }) => (
                    <TagInput block id={field.name} value={field.value} onChange={value => field.onChange(value)} placeholder="Presiona ENTER para guardar la palabra" />
                )}
            />
            <span className="spanError">{!!errors['palabras'] && errors['palabras'].message}</span>
        </div>
        <Stack justifyContent="space-between" alignItems="flex-start" spacing={20} style={{ margin: '20px 0' }}>
            <Stack.Item basis={'50%'}>
                <label htmlFor="before">Tipo de respuesta:</label>
                <Controller
                    name="type"
                    rules={{
                        required: {
                            value: true,
                            message: "Tipo de respuesta",
                        }
                    }}
                    control={metods.control}
                    render={({ field }) => (
                        <SelectPicker
                            id={field.name}
                            value={field.value}
                            onChange={value => field.onChange(value)}
                            data={["Respuesta a Plantilla", "Respuesta a Texto"].map(
                                (t, i) => ({ label: t, value: i + 1 })
                            )}
                            searchable={false}
                            style={{ width: '100%' }}
                            placeholder="Tipo de respuesta"
                        />
                    )}
                />
                <span className="spanError">{!!errors['type'] && errors['type'].message}</span>
            </Stack.Item>
            <Stack.Item basis={'50%'}>
                <label htmlFor="after">{`Respuesta a esta(s) palabra(s)`}</label>
                <Controller
                    name="respuesta"
                    rules={{
                        required: {
                            value: true,
                            message: "Elige la respuesta para estas palabras",
                        }
                    }}
                    control={metods.control}
                    render={({ field }) => (
                        <SelectPicker
                            id={field.name}
                            value={field.value}
                            onChange={value => field.onChange(value)}
                            data={flowsList?.map(
                                f => ({ label: f.name ?? f.nombre, value: `${f.id}_${f.tipoRespuesta}` })
                            )}
                            searchable={false}
                            style={{ width: '100%' }}
                            placeholder="Tipo de respuesta"
                        />
                    )}
                />
                <span className="spanError">{!!errors['respuesta'] && errors['respuesta'].message}</span>
            </Stack.Item>
        </Stack>
        {metods.watch('respuesta') && <IconButton style={{ marginBottom: 20, width: '100%' }} icon={<Eye />} appearance="default" onClick={() => mostrarPrew()} />}
        <ButtonToolbar>
            <Button type="submit" appearance="primary">
                Guardar
            </Button>
            <Button type="button" onClick={cerrar} appearance="subtle">
                Cancel
            </Button>
        </ButtonToolbar>
    </form>
}

export default function TextosActivadores() {

    const queryClient = useQueryClient()
    const servicio = queryClient.getQueriesData('services')[0][1][0]
    const idBase64 = btoa(servicio.id.toString())
    const [mostarModalBox, setMostrarModalBox] = useState(false)
    const [activadorSelected, setActivadorSelected] = useState(0)
    const [activadorObjectModify, setActivadorObjectModify] = useState({})
    const { data: activadores = [], isLoading } = useQuery(["activadores"], () => getDatos('/bot-whatsapp/getActivadores', { s: idBase64 }))

    const [flowSelected, setFlowSelected] = useState(null)
    const [mt, setMt] = useState(false);

    const { flowsList } = useGetFlowResponses()

    const { mutate: eliminar, isLoading: isEliminando } = useMutation({
        mutationFn: (datos) => mutarDatos({ ruta: '/bot-whatsapp/eliminarActivador', parametros: datos })
    })

    function eliminarActivador(id) {
        customSwall.fire({
            icon: 'warning',
            title: `Eliminar Activador?`,
            text: `Estás segur@, esta acción no se puede revertir`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, Eliminar'
        }).then(response => {
            if (response.isConfirmed) {
                eliminar({ id: id, s: idBase64 }, {
                    onSuccess: (data) => {
                        if (data.ok) {
                            queryClient.invalidateQueries(["activadores"])
                        } else {
                            customSwall.fire({
                                icon: 'error',
                                title: 'Error',
                                text: !!data.error ? data.error : 'no se pudo eliminar',
                                showConfirmButton: true,
                            })
                        }
                    },
                })
            }
        })
    }

    function cerrarPrew() {
        setFlowSelected(null)
        setMt(false)
    }

    function mostrarPrew(id, tipo) {
        const flow = flowsList.find((flow) => flow.id === id && flow.tipoRespuesta === tipo)
        setFlowSelected(flow)
        setMt(true)
    }

    function cerrarModal() {
        setMostrarModalBox(false)
        setActivadorSelected(0)
        setActivadorObjectModify({})
    }

    function modificarActivador(id) {
        setActivadorSelected(id)
        setMostrarModalBox(true)
        setActivadorObjectModify(activadores[id])
    }

    const style = {
        marginTop: 60,
        display: 'flex',
        gap: '30',
        overflow: 'auto',
        alignItems: 'flex-start',
    }

    return <>
        <HeaderSection title="Activadores con texto">
            <ButtonToolbar>
                <Button appearance="primary" onClick={() => setMostrarModalBox(true)}>Nuevo Activador</Button>
            </ButtonToolbar>
        </HeaderSection>
        <div style={style}>
            {mt && <ModalPrevFlows flows={[flowSelected]} cerrar={cerrarPrew} />}
            {(isLoading || isEliminando) && <Spiner />}
            <Modal backdrop={true} keyboard={false} open={mostarModalBox} onClose={cerrarModal}>
                <Modal.Header>
                    <Modal.Title>Activador de respuesta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ElementActivadorConfig cerrar={() => cerrarModal()} id={activadorSelected} defaultValues={activadorObjectModify} />
                </Modal.Body>
            </Modal>
            <table className="tabla-productos tabla-activadores">
                <thead>
                    <tr>
                        <th width={'20px'} >#</th>
                        <th>Palabras</th>
                        <th>Responder a</th>
                        <th>Respuesta</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.values(activadores)?.map((activador, index) => (
                            <tr key={activador.id}>
                                <td width={'20px'}>{index + 1}</td>
                                <td >
                                    <TagInput plaintext defaultValue={activador.palabras} />
                                </td>
                                <td >{activador.type === 2 ? 'Texto' : 'Plantilla'}</td>
                                <td >{activador.respuesta}</td>
                                <td className='td-acciones'>
                                    <button className="btn btn-azul" onClick={() => mostrarPrew(activador.respuestaId, activador.tipoRespuesta)}>
                                        <Icons req={'eye'} fill="var(--blanco)" />
                                    </button>
                                    <button onClick={() => modificarActivador(activador.id)} className="btn btn-azul">Modificar</button>
                                    <button onClick={() => eliminarActivador(activador.id)} className="btn btn-rojo">Eliminar</button>
                                </td>
                            </tr>
                        )) ?? <p>No hay datos</p>}
                </tbody>
            </table>
        </div>
    </>
}