import { useNavigate } from "react-router-dom"
import Icons from "./Utils/Icons"

const HeaderSection = ({ children, title }) => {

    const navigate = useNavigate()

    return <div className="headerSection">
        <div className="headerSectionLeft">
            <button className="btnHeaderSectionVolver" onClick={() => navigate(-1)}><Icons iconReq='arrow-left' size={20}/></button>
            <div className="headerSectionTitle">
                <h4>{ title }</h4>
            </div>
        </div>
        <div className="headerSectionRight">
            { children }
        </div>
    </div>
}

export default HeaderSection