import { useQuery } from 'react-query';
import styles from '../../assets/css/formularios.module.css';
import { getDatos } from '../../providers/dataServer';

const SelectRequerido = ({ cerrar, setRequerido }) => {

    const { data: requeridos } = useQuery(["requeridos"], () => getDatos('/bot-whatsapp/getRequeridos'));

    return <div className={ styles.contSelectReq }>
        <div className={ styles.modalReq }>
            <button className={ styles.btnCerrarModal } type='button' onClick={ cerrar }>Cerrar</button>
            <h3>Elige un tipo de requerido en esta pregunta</h3>
            <div className={ styles.modalReqBody }>
                {
                    requeridos?.map((requerido, index) => <div key={ index }
                    onClick={ () => setRequerido({id: requerido.id, nombre: requerido.nombre, tipo: requerido.tipo, clave: requerido.clave, config: JSON.parse(requerido.config_req)}) }
                    className={ styles.CardRequerido } >
                        <h2>{ requerido.nombre }</h2>
                        <p>{ requerido.descripcion }</p>
                    </div>) 
                }
            </div>
        </div>
    </div>
}

export default SelectRequerido