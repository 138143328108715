import React from "react";

export const IsotipoLogo = React.forwardRef((props, ref) => (
   <svg {...props} width="1em" height="1em" viewBox="0 0 120 120" ref={ref}>
      <g>
         <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="5.1848" y1="60.1937" x2="115.0001" y2="60.1937">
            <stop offset="0" stopColor="#00B3FF" />
            <stop offset="1" stopColor="#0043FF" />
         </linearGradient>
         <path
            fill="url(#SVGID_1_)"
            d="M115,62.34c-0.22,4.76,0,9.61-0.82,14.26c-0.47,2.64-2.21,5.4-4.12,7.39c-8.62,8.96-17.5,17.67-26.31,26.45 c-3.06,3.05-6.81,4.55-11.14,4.55C52.41,115,32.2,115,12,114.99c-4.26,0-6.8-2.53-6.8-6.79C5.18,88.07,5.18,67.93,5.19,47.8 c0-4.55,1.62-8.43,4.84-11.65c8.66-8.64,17.31-17.28,25.95-25.95c3.23-3.24,7.11-4.81,11.66-4.82c3.41,0,6.82,0,10.22,0 c0.14,0.2,0.27,0.4,0.41,0.6c-2.14,1.99-4.34,3.92-6.41,5.98C40.81,22.97,29.78,33.99,18.8,45.07c-0.67,0.68-1.14,1.89-1.15,2.85 c-0.08,11.41-0.02,22.82-0.08,34.22c-0.01,1.48,0.48,2.08,1.88,2.6c7.72,2.86,13.1,8.16,15.79,15.98c0.54,1.58,1.32,1.89,2.82,1.88 c11.27-0.06,22.54-0.06,33.81,0.01c1.59,0.01,2.67-0.53,3.75-1.62c12.5-12.56,25.05-25.09,37.57-37.63 c0.38-0.38,0.66-0.87,0.99-1.31C114.45,62.15,114.73,62.24,115,62.34z"
         />
         <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="50.4125" y1="37.5323" x2="114.835" y2="37.5323">
            <stop offset="0" stopColor="#FFFF00" />
            <stop offset="1" stopColor="#FF4E00" />
         </linearGradient>
         <path
            fill="url(#SVGID_2_)"
            d="M50.41,60.76c4.38-4.35,9.11-9.03,13.82-13.73C77.5,33.76,90.8,20.53,103.97,7.17 c2.12-2.15,4.31-2.15,6.7-1.31c2.57,0.89,3.87,2.93,4.13,5.64c0.18,1.86-0.35,3.27-1.79,4.69C95.31,33.76,77.7,51.42,60.06,69.06 c-0.33,0.33-0.7,0.64-0.72,0.67C56.37,66.75,53.47,63.83,50.41,60.76z"
         />
         <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="65.6648" y1="60.3691" x2="114.9338" y2="60.3691">
            <stop offset="0" stopColor="#FF00B0" />
            <stop offset="1" stopColor="#FF060A" />
         </linearGradient>
         <path
            fill="url(#SVGID_3_)"
            d="M71.47,90.7c-1.99-3.96-3.91-7.8-5.81-11.57c16.3-16.28,32.63-32.6,49.14-49.09c0,4.06-0.28,8.1,0.08,12.07 c0.35,3.92-1.06,6.55-3.82,9.26c-12.86,12.58-25.5,25.38-38.22,38.1C72.42,89.89,71.95,90.28,71.47,90.7z"
         />
         <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="29.5293" y1="29.889" x2="89.9345" y2="29.889">
            <stop offset="0" stopColor="#00D200" />
            <stop offset="1" stopColor="#006C00" />
         </linearGradient>
         <path
            fill="url(#SVGID_4_)"
            d="M40.84,54.41c-3.49-1.76-7.34-3.69-11.31-5.69c0.48-0.53,0.91-1.05,1.39-1.52 c13.5-13.51,26.99-27.02,40.54-40.48c0.73-0.72,1.97-1.25,2.99-1.28c5.19-0.13,10.39-0.06,15.48-0.06 C73.6,21.7,57.3,37.97,40.84,54.41z"
         />
      </g>
   </svg>
));
