import { useQuery, useQueryClient } from "react-query"
import { getDatos } from "../../providers/dataServer"

const useGetFormActions = () => {

    const queryClient = useQueryClient()
    const servicio = queryClient.getQueriesData('services')[0][1][0]
    const idBase64 = btoa(servicio.id.toString())

    const cacheFormActions = queryClient.getQueryData(["formActions"]);
    let isUndefined = false;
    //console.log(cacheFlowsList)

    const { data: formActions = [], isLoading, refetch } = useQuery(["formActions"], () => getDatos('/bot-whatsapp/getFormActions', { s: idBase64 }), {
        enabled: false,
    })

    if (typeof cacheFormActions === 'undefined') {
        isUndefined = true
        refetch()
    }

    return isUndefined ? {
        isLoading: isLoading,
        formActions: formActions
    } : {
        isLoading: false,
        formActions: cacheFormActions
    }

}

export default useGetFormActions