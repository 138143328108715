import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { getDatos } from '../providers/dataServer';

const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState({
        authenticated: false,
        message: 'La sesión a caducado, vuelve a ingresar tus datos'
    });
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const user = JSON.parse(localStorage.getItem('userData'));
            if (user) {
                const token = user.token;
                if (token) {
                    const decodedToken = jwtDecode(token);
                    const currentTime = Date.now() / 1000;
                    const timeLeft = decodedToken.exp - currentTime;

                    if (timeLeft < 300) { // Si quedan menos de 5 minutos
                        try {
                            const newData = await getDatos('/user/refreshToken');
                            if (newData.ok) {
                                localStorage.removeItem('userData');
                                localStorage.setItem('userData', JSON.stringify(newData.user));
                                setIsAuthenticated({
                                    authenticated: true,
                                });
                            } else {
                                localStorage.removeItem('userData');
                                setIsAuthenticated({
                                    authenticated: false,
                                    message: 'La sesión ha caducado, vuelve a ingresar tus datos'
                                });
                                navigate('/login');
                            }
                        } catch (error) {
                            console.error('Error al refrescar el token:', error);
                            localStorage.removeItem('userData');
                            setIsAuthenticated({
                                authenticated: false,
                                message: 'Error al refrescar el token, vuelve a ingresar tus datos'
                            });
                            navigate('/login');
                        }
                    } else if (decodedToken.exp < currentTime) {
                        localStorage.removeItem('userData');
                        setIsAuthenticated(false);
                        navigate('/login');
                    } else {
                        setIsAuthenticated({
                            authenticated: true,
                        });
                    }
                } else {
                    setIsAuthenticated({
                        authenticated: false,
                        message: 'No hay una sesión activa, ingresa tus datos'
                    });
                }
            } else {
                setIsAuthenticated({
                    authenticated: false,
                    message: 'No hay una sesión activa, ingresa tus datos'
                });
            }
        };

        checkAuth();
    }, [navigate, setIsAuthenticated]);

    return isAuthenticated;
};

export default useAuth;