import { useState } from 'react'
import styles from '../../assets/css/preview-messages.module.css'
import PreviewmessageItem from './PreviewMessageItem'
import PrevLista from './PrevLista'

const ContPrevFlows = ({ flows = [], cerrar, style= {} }) => {

    const [showListComponent, setShowListComponent] = useState(false)
    const [listtoShow, setListtoShow] = useState(null)

    function mostrarlista(lista) {
        setListtoShow(lista)
        setShowListComponent(true)
    }

    function cerrarlista() {
        setListtoShow(null)
        setShowListComponent(false)
    }

    return <div className={styles.contPrev} style={style}>
        <div className={styles.modalPrevHeader} style={{ width: '70%' }}>
            <h6 style={{ textAlign: 'center', width: '100%' }}>Previsualizar Mensaje</h6>
        </div>
        <div className={styles.modalPrev} style={{... showListComponent ? { height: '80vh' }: {}}}>
            {
                flows.map((flow, index) => <PreviewmessageItem key={index} message={flow} showList={(lista) => mostrarlista(lista)} />)
            }
        </div>
        {showListComponent && <PrevLista lista={ listtoShow } cerrarlista={ cerrarlista }/>}
    </div>
}

export default ContPrevFlows