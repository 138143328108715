import { Sidenav, Nav } from 'rsuite';
import GearCircleIcon from '@rsuite/icons/legacy/GearCircle';
import Exit from '@rsuite/icons/legacy/Exit';
import { IsotipoLogo } from './Utils/logosNova';
import { BotWhatsappIcon } from './Utils/CustomSvgIcons';
import Icon from '@rsuite/icons/lib/Icon';
import { customSwall } from './CustomSwal';
import { Link, useNavigate } from 'react-router-dom';

const SideNavBar = ({ appearance, openKeys, expanded, onOpenChange, onExpand, style, services, ...navProps }) => {

  const navigate =  useNavigate()
  const userData = JSON.parse(localStorage.getItem('userData'))

  function cerrarSession() {
    customSwall.fire({
      title: 'Cerrar Sesión',
      text: 'Deseas salir del sistema de administración?',
      icon: 'question',
      confirmButtonText: 'Cerrar Sesión',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('userData');
        window.location.reload()
      }
    });
  }

  const styleItems = {
    textAlign: 'start',
    fontSize: '12px'
  }

  return (
    <Sidenav
      style={style}
      appearance={appearance}
      expanded={expanded}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
    >
      <Sidenav.Body>
        <Nav {...navProps}>
          <Nav.Item eventKey="1" active icon={<Icon as={IsotipoLogo} />} as={Link} href='/' style={{ fontWeight: 600 }}>
            Nova Creation
            <p style={{ fontSize: '12px', fontWeight: 400, color: 'black' }}>{`${userData?.nombre} ${userData?.apellido}`}</p>
          </Nav.Item>
          <Nav.Menu eventKey="2" as={'div'} title="Bot WhatsApp" icon={<Icon as={BotWhatsappIcon} />} style={{ fontWeight: 500 }}>
          {
            services.map((service, index) => <Nav.Menu key={service.id} as={'div'} eventKey={ `2-${index+1}` } title={ service.whatsapp } style={{ fontWeight: 600, fontSize: '12px' }}>
              <Nav.Item as={ 'button' } onSelect={ () => navigate(`/bot-whatsapp/${ btoa(service.id.toString()) }`) } style={styleItems}>Conversaciones</Nav.Item>
              <Nav.Item as={ 'button' } onSelect={ () => navigate(`/bot-whatsapp/${ btoa(service.id.toString()) }/lista-de-respuestas`) } style={styleItems}>Respuestas</Nav.Item>
              <Nav.Item as={ 'button' } onSelect={ () => navigate(`/bot-whatsapp/${ btoa(service.id.toString()) }/arbol-del-bot`) } style={styleItems}>Arbol de Respuestas</Nav.Item>
              <Nav.Item as={ 'button' } onSelect={ () => navigate(`/bot-whatsapp/${ btoa(service.id.toString()) }/textos-activadores`) } style={styleItems}>Textos Activadores</Nav.Item>
              <Nav.Item as={ 'button' } onSelect={ () => navigate(`/bot-whatsapp/${ btoa(service.id.toString()) }/formularios`) } style={styleItems}>Formularios</Nav.Item>
            </Nav.Menu>)
          }
          </Nav.Menu>
          <Nav.Item eventKey="5" icon={<Exit />} onSelect={ cerrarSession } as={'button'} style={{ textAlign: 'start', color: 'red'}}>
            Cerrar sesión
          </Nav.Item>
        </Nav>
      </Sidenav.Body>
      <Sidenav.Toggle onToggle={onExpand} />
    </Sidenav>
  );
};

export default SideNavBar