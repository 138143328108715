import { useNavigate, useParams } from "react-router-dom";
import Spiner from "../components/Spiner";
import { getDatos, mutarDatos } from "../providers/dataServer";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { customSwall } from "../components/CustomSwal";
import FormTemplateSelect from "./components/FormTempalteSelect";
import { useState } from "react";
import HeaderSection from "../components/HeaderSection";
import { Button, ButtonToolbar, Placeholder, Table } from "rsuite";

export default function Formularios() {

    const [selectForm, setSelectForm] = useState(false)
    const queryClient = useQueryClient()
    const navigate = useNavigate();
    let params = useParams();
    const { data: formularios = [], isLoading } = useQuery(["formularios"], () => getDatos('/bot-whatsapp/getFormularios', { s: params["idService"] }))

    const { Column, HeaderCell, Cell } = Table;

    const { mutate: eliminar, isLoading: isEliminando } = useMutation({
        mutationFn: (datos) => mutarDatos({ ruta: '/bot-whatsapp/delFormulario', parametros: datos })
    })

    function eliminarFormulario(id) {
        customSwall.fire({
            icon: 'warning',
            title: `Eliminar Formulario?`,
            text: `Estás segur@, esta acción no se puede revertir`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Si, Eliminar'
        }).then(response => {
            if (response.isConfirmed) {
                eliminar({ id: id, s: params["idService"] }, {
                    onSuccess: (data) => {
                        if (data.ok) {
                            queryClient.invalidateQueries(["formularios"])
                        } else {
                            customSwall.fire({
                                icon: 'error',
                                title: 'Error',
                                text: !!data.error ? data.error : 'no se pudo eliminar',
                                showConfirmButton: true,
                            })
                        }
                    },
                })
            }
        })
    }

    function newForm(data) {
        if (!!data) {
            sessionStorage.setItem('formTemplate', JSON.stringify(data))
            navigate(`/bot-whatsapp/${params["idService"]}/agregar-modificar-formulario?template=true`)
        } else {
            navigate(`/bot-whatsapp/${params["idService"]}/agregar-modificar-formulario`)
        }
    }

    const loaderContainerStyle = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'var(--rs-bg-card)',
        padding: 20,
        zIndex: 1
    };

    const renderLoading = () => {
        return <div style={loaderContainerStyle}>
            <Placeholder.Grid rows={9} columns={5} active />
        </div>
    }

    return <>
        <HeaderSection title="Formularios">
            <ButtonToolbar>
                <Button appearance="primary" onClick={() => setSelectForm(true)} >Nuevo Formularior</Button>
            </ButtonToolbar>
        </HeaderSection>
        {selectForm && <FormTemplateSelect cerrar={() => setSelectForm(false)} show={selectForm} formSelected={(data) => newForm(data)} />}
        {/*(isLoading || isEliminando) && <Spiner />*/}
        <div style={{ marginTop: 60 }}>
            <Table
                loading={(isLoading || isEliminando)}
                height={400}
                data={ formularios }
                renderLoading={renderLoading}
            >
                <Column width={60} align="center" fixed>
                    <HeaderCell>#</HeaderCell>
                    <Cell >
                        {(_, index) => index + 1}
                    </Cell>
                </Column>

                <Column width={300}>
                    <HeaderCell>Nombre</HeaderCell>
                    <Cell dataKey="nombre" />
                </Column>

                <Column width={500}>
                    <HeaderCell>Descripcion</HeaderCell>
                    <Cell dataKey="descripcion" />
                </Column>

                <Column width={200} fixed="right">
                    <HeaderCell>Acciones</HeaderCell>

                    <Cell style={{ padding: '6px' }}>
                        {rowData => (<>
                            <Button appearance="link" onClick={() => navigate(`/bot-whatsapp/${params["idService"]}/agregar-modificar-formulario?idf=${rowData.id}`)}>
                                Editar
                            </Button>
                            <Button appearance="link" onClick={() => eliminarFormulario(rowData.id)}>
                                Eliminar
                            </Button>
                        </>)}
                    </Cell>
                </Column>
            </Table>
        </div>
    </>
}