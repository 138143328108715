import { useState } from 'react'
import styles from '../../assets/css/preview-messages.module.css'
import Icons from '../../components/Icons'
import PreviewmessageItem from './PreviewMessageItem'
import PrevLista from './PrevLista'

const ModalPrevFlows = ({ flows = [], cerrar, style= {} }) => {

    const [showListComponent, setShowListComponent] = useState(false)
    const [listtoShow, setListtoShow] = useState(null)

    function mostrarlista(lista) {
        setListtoShow(lista)
        setShowListComponent(true)
    }

    function cerrarlista() {
        setListtoShow(null)
        setShowListComponent(false)
    }

    return <div className={styles.contModalPrev} style={style}>
        <div className={styles.modalPrevHeader}>
            <h6>Previsualizar Mensaje</h6>
            <button className={styles.btnCerrarModal} onClick={cerrar}>
                <Icons req={'close_x'} fill='white' height='1.5em' />
            </button>
        </div>
        <div className={styles.modalPrev} style={{... showListComponent ? { height: '80vh' }: {}}}>
            {
                flows.map((flow, index) => <PreviewmessageItem key={index} message={flow} showList={(lista) => mostrarlista(lista)} />)
            }
        </div>
        {showListComponent && <PrevLista lista={ listtoShow } cerrarlista={ cerrarlista }/>}
    </div>
}

export default ModalPrevFlows