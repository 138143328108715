import { useQuery, useQueryClient } from "react-query"
import { getDatos } from "../../providers/dataServer"

const useFlowConfigsList = () => {

    const queryClient = useQueryClient()
    const servicio = queryClient.getQueriesData('services')[0][1][0]
    const idBase64 = btoa(servicio.id.toString())

    const cacheFlowsList = queryClient.getQueryData(["flowListConfig"]);
    let isUndefined = false;
    /*console.log('--- EN CACHE ---')
    console.log(cacheFlowsList)*/

    const { data: {
        keyWords,
        config = { registro: false, activo: false } } = {}, isLoading, refetch } = useQuery(["flowListConfig"], () => getDatos('/bot-whatsapp/getFlowListConfig', { s: idBase64 }), {
        enabled: false,
        onSuccess: (data) => {
            sessionStorage.setItem('keyWordsOrigin', JSON.stringify(data))
        }
    })

    if (typeof cacheFlowsList === 'undefined') {
        isUndefined = true
        refetch()
    }

    return isUndefined ? {
        isLoadingFlows: isLoading,
        keyWordsList: keyWords,
        config: config
    } : {
        isLoadingFlows: false,
        keyWordsList: cacheFlowsList.keyWords,
        config: cacheFlowsList.config
    }

}

export default useFlowConfigsList