import { useMutation, useQueryClient } from "react-query"
import { mutarDatos } from "../providers/dataServer"
import Spiner from "../components/Spiner"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Controller, FormProvider, useForm, useWatch } from "react-hook-form"
import '../assets/css/answers.css';
import styles from '../assets/css/formularios.module.css';
import FormularioItem from "./components/FormularioItem"
import { useEffect, useState } from "react"
import TypeFormItemSelector from "./components/TypeFormItemSelector"
import { customSwall } from "../components/CustomSwal"
import FormularioItemFin from "./components/FormularioItemFin"
import HeaderSection from "../components/HeaderSection"
import { Button, ButtonToolbar, IconButton, Input, InputGroup, SelectPicker, Stack, TagPicker, Tooltip, Whisper } from "rsuite"
import ContPrevFlows from "./components/ContPrevFlows"
import InfoRoundIcon from '@rsuite/icons/InfoRound';
import PlusIcon from '@rsuite/icons/Plus';
import useGetFormActions from "../hooks/bot/useGetFormActions"
import useGetFlowResponses from "../hooks/bot/useGetFlowResponses"

function FormItemElementConfig({ register, errors, element, width = 100 }) {
    const clave = `xconfig-${element.clave}`
    return <div className={styles.formItem} style={{ width: `${width}%` }}>
        <label htmlFor={clave}>{element.label}</label>
        <input type={element.tipo} {...register} placeholder={element.placeholder} />
        <span className={styles.errorForm}>{errors[clave] && errors[clave].message}</span>
    </div>
}

export default function AgregarModificarFormulario() {

    const [elementos, setElementos] = useState([])
    const [newItemSteepSlector, setNewItemSteepSlector] = useState(false)
    const navigate = useNavigate()
    const [params] = useSearchParams();
    const idFormParam = params.get('idf') ?? 0
    const [esModificar, setEsModificar] = useState(false)
    const [isTemplate, setIsTemplate] = useState(false)
    const [template, setTemplate] = useState({})
    const [finalizadosConfig, setFinalizadosConfig] = useState([])
    const [finalizadosOpciones, setFinalizadosOpciones] = useState([])
    const [ selectedFlowCorrecto, setSelectedFlowCorrecto ] = useState(null)
    const [ selectedFlowIncorrecto, setSelectedFlowIncorrecto ] = useState(null)

    const [previewList, setPreviewList] = useState([])

    const queryClient = useQueryClient()
    const servicio = queryClient.getQueriesData('services')[0][1][0]
    const idBase64 = btoa(servicio.id.toString())
    const { flowsList, getFlowItem } = useGetFlowResponses()

    const { formActions } = useGetFormActions()

    const metods = useForm({
        defaultValues: {
            nombreFormulario: 'Nuevo Formulario',
        },
    })
    const { formState: { errors }, watch, reset, setValue, control } = metods;

    const total = useWatch({ control });

    useEffect(() => {
        const groupedObject = getObjectPreview(total)
        setPreviewList(groupedObject)
    }, [total])

    useEffect(() => {
        if (formActions.length > 0) {
            setFinalizadosOpciones(formActions)
        }
    }, [formActions])

    useEffect(() => {
        let finalizadosConfig = []
        if (typeof watch('accionesFinales') !== 'undefined') {
            const acciones = watch('accionesFinales')
            for (let i = 0; i < acciones.length; i++) {
                let element = finalizadosOpciones.find(e => e.action === acciones[i])
                if (typeof element?.config !== 'undefined') {
                    if (typeof element?.config !== 'object') {
                        element.config = JSON.parse(element?.config)
                    }
                    for (let a = 0; a < element?.config.length; a++) {
                        finalizadosConfig.push(element?.config[a])
                    }
                }
            }
        }
        //console.log(finalizadosConfig)
        setFinalizadosConfig(finalizadosConfig)
    }, [watch('accionesFinales')])

    const { mutate: fetchToEdit, isLoading: isFetching } = useMutation({
        mutationFn: (datos) => mutarDatos({ ruta: '/bot-whatsapp/getForm', parametros: datos }),
        onSuccess: (data) => {
            setValue('nombreFormulario', data.nombre)
            setValue('descripcion', data.descripcion)
            let finalFlow = JSON.parse(data.flow_final)
            let accionesFinalizar = JSON.parse(data.funcion_finalizar)
            setValue('respuestaFinalCorrecto-text', finalFlow.correcto.text)
            setValue('respuestaFinalCorrecto-image', finalFlow.correcto.image)
            setValue('respuestaFinalIncorrecto-text', finalFlow.error.text)
            setValue('respuestaFinalIncorrecto-image', finalFlow.error.image)
            setValue('respuestaFinalCorrecto', finalFlow.correcto.value)
            setValue('respuestaFinalIncorrecto', finalFlow.error.value)
            setValue('respuestaFinalCorrectoFlowID', finalFlow.correcto.flow ?? '')
            setValue('respuestaFinalIncorrectoFlowID', finalFlow.error.flow ?? '')
            setValue('accionesFinales', accionesFinalizar)
            let obj = JSON.parse(data.flow)
            let templateConfigs = JSON.parse(data.template_config)
            let formConfig = JSON.parse(data.form_config)
            let accionesFinalesConfig = JSON.parse(data.acciones_finales)
            setFinalizadosOpciones(obj => {
                const combinedArray = [...obj, ...accionesFinalesConfig]
                const actionMap = new Map();
                const uniqueObjects = combinedArray.filter(obj => {
                    if (!actionMap.has(obj.action)) {
                        actionMap.set(obj.action, true);
                        return true;
                    }
                    return false;
                });
                return uniqueObjects
            })
            setElementos(obj)
            setTemplate({
                config: templateConfigs
            })
            Object.keys(formConfig).forEach(key => {
                setValue(`xconfig-${key}`, formConfig[key])
            });
        }
    })

    useEffect(() => {
        const idFormParam = params.get('idf') ?? 0
        const isTemplate = params.get('template') ? (params.get('template') === 'true') : false
        if (isTemplate) {
            const template = JSON.parse(sessionStorage.getItem('formTemplate'))
            let tempSet = {
                ...template,
            }
            let elementosTemplate = []
            tempSet.flow = JSON.parse(template.flow)
            tempSet.config = JSON.parse(template.config)
            tempSet.accionesFinales = JSON.parse(template.acciones_finales)
            tempSet.finalizado = JSON.parse(template.finalizado)
            for (let i = 0; i < tempSet.flow.length; i++) {
                let groupID = Math.random().toString(36).substr(2, 9);
                for (let x = 0; x < tempSet.flow[i].length; x++) {
                    let newItem = tempSet.flow[i][x]
                    newItem['esPlantilla'] = true
                    newItem['plantillaGroup'] = groupID
                    elementosTemplate = [...elementosTemplate, newItem]
                }
            }
            setTemplate(tempSet)
            setElementos(...elementos, elementosTemplate)
            setIsTemplate(true)
            setFinalizadosOpciones(tempSet.accionesFinales)
            setValue('nombreFormulario', template.nombre)
            setValue('descripcion', template.descripcion)
            setValue('accionesFinales', tempSet.finalizado)
        } else if (idFormParam > 0) {
            fetchToEdit({ id: idFormParam, s: idBase64 })
            setEsModificar(true)
        }
    }, [params, fetchToEdit, idBase64])

    const { mutate: guardarNuevo, isLoading } = useMutation({
        mutationFn: (datos) => mutarDatos({ ruta: '/bot-whatsapp/newForm', parametros: datos })
    })

    const { mutate: modificar, isModificando } = useMutation({
        mutationFn: (datos) => mutarDatos({ ruta: '/bot-whatsapp/setForm', parametros: datos })
    })

    const onSubmit = async data => {
        const groupedObject = getObjectElement(data)
        const respuestaFinal = {
            correcto: {
                image: groupedObject['respuestaFinalCorrecto-image'],
                text: groupedObject['respuestaFinalCorrecto-text'],
                value: groupedObject['respuestaFinalCorrecto'],
                flow: groupedObject['respuestaFinalCorrectoFlowID'],
            },
            error: {
                image: groupedObject['respuestaFinalIncorrecto-image'],
                text: groupedObject['respuestaFinalIncorrecto-text'],
                value: groupedObject['respuestaFinalIncorrecto'],
                flow: groupedObject['respuestaFinalIncorrectoFlowID'],
            }
        }
        let configForm = {}
        Object.keys(groupedObject).forEach(key => {
            if (key.startsWith('xconfig-')) {
                const clave = key.match(/-(\w+)/) ? key.match(/-(\w+)/)[1] : null;
                configForm[clave] = groupedObject[key]
            }
        });
        if (typeof groupedObject?.preguntas === 'undefined') {
            customSwall.fire({
                icon: 'error',
                title: 'No hay preguntas',
                text: 'Crea por lo menos una pregunta en este formulario',
            }).then()
            //return
        }
        //console.log(groupedObject)
        if (esModificar) {
            /*/console.log({
                id: idFormParam,
                nombreFormulario: groupedObject.nombreFormulario,
                flowFinal: JSON.stringify(respuestaFinal),
                descripcion: groupedObject.descripcion,
                preguntas: JSON.stringify(groupedObject.preguntas),
                dataConfig: JSON.stringify(configForm),
                finalizados: JSON.stringify(data.accionesFinales),
                s: idBase64
            })**/
            modificar({
                id: idFormParam,
                nombreFormulario: groupedObject.nombreFormulario,
                flowFinal: JSON.stringify(respuestaFinal),
                descripcion: groupedObject.descripcion,
                preguntas: JSON.stringify(groupedObject.preguntas),
                dataConfig: JSON.stringify(configForm),
                finalizados: JSON.stringify(data.accionesFinales),
                s: idBase64
            },
                {
                    onSuccess: (data) => {
                        if (data.ok) {
                            customSwall.fire({
                                icon: 'success',
                                title: `Correcto!`,
                                text: `Se modificó el formulario correctamente`,
                                showConfirmButton: true,
                            }).then(response => {
                                queryClient.invalidateQueries(["formularios"])
                                navigate(-1)
                            })
                        } else {
                            customSwall.fire({
                                icon: 'error',
                                title: 'Error',
                                text: !!data.error ? data.error : 'ocurrio un error',
                                showConfirmButton: true,
                            })
                        }
                    }
                })
        } else {
            guardarNuevo({
                nombreFormulario: groupedObject.nombreFormulario,
                flowFinal: JSON.stringify(respuestaFinal),
                descripcion: groupedObject.descripcion,
                preguntas: JSON.stringify(groupedObject.preguntas),
                dataConfig: JSON.stringify(configForm),
                finalizados: JSON.stringify(data.accionesFinales),
                accionesFinalesConfig: JSON.stringify(finalizadosOpciones),
                templateConfig: JSON.stringify(template?.config ?? []),
                s: idBase64
            },
                {
                    onSuccess: (data) => {
                        if (data.ok) {
                            customSwall.fire({
                                icon: 'success',
                                title: `Correcto!`,
                                text: `Se guardó el formulario correctamente`,
                                showConfirmButton: true,
                            }).then(response => {
                                queryClient.invalidateQueries(["formularios"])
                                navigate(-1)
                            })
                        } else {
                            customSwall.fire({
                                icon: 'error',
                                title: 'Error',
                                text: !!data.error ? data.error : 'ocurrio un error',
                                showConfirmButton: true,
                            })
                        }
                    }
                })
        }
    }

    function addNewItemSteep(datos) {
        setElementos([...elementos, datos])
        setNewItemSteepSlector(false)
    }

    const unsetItemSteep = (eIndex) => {
        let obj = watch()
        let newD = getObjectElement(obj)
        let itemmm = newD['preguntas'].map((item, index) => (index !== eIndex ? item : null)).filter(Boolean);
        reset()
        Object.keys(newD).forEach(key => {
            if (!key.startsWith('preguntas')) {
                setValue(key, newD[key])
            }
        });
        setElementos(itemmm)
    };

    const getObjectElement = data => {
        let object = Object.keys(data).reduce((acc, key) => {
            if (key.startsWith('steep_')) {
                const [index, subKey] = key.split('-');
                if (!acc.preguntas) {
                    acc.preguntas = [];
                }
                const questionIndex = parseInt(index.split('_')[1], 10);
                if (!acc.preguntas[questionIndex]) {
                    acc.preguntas[questionIndex] = {};
                }
                acc.preguntas[questionIndex][subKey] = data[key];
            } else {
                acc[key] = data[key];
            }
            return acc;
        }, {})
        return object
    }

    const getObjectPreview = data => {
        let object = Object.keys(data).reduce((acc, key) => {
            if (key.startsWith('steep_')) {
                const [index, subKey] = key.split('-');
                if (!acc.flow) {
                    acc.flow = [];
                }
                const questionIndex = parseInt(index.split('_')[1], 10);
                if (!acc.flow[questionIndex]) {
                    acc.flow[questionIndex] = {};
                }
                acc.flow[questionIndex][subKey] = data[key];
                if (acc.flow[questionIndex][subKey] === 'buttonApi') {
                    acc.flow[questionIndex][subKey] = 'buttons'
                }
            } else {
                if (key.startsWith('respuestaFinalCorrecto')) {
                    if (watch('respuestaFinalCorrecto') === 'Texto/Imagen') {
                        if (!acc.flow_final_correcto) acc.flow_final_correcto = {};
                        const [prefix, subKey] = key.split('-');
                        acc.flow_final_correcto[subKey] = data[key];
                        setSelectedFlowCorrecto(null)
                    } else if (watch('respuestaFinalCorrecto') === 'Respuesta del arbol') {
                        const flow = getFlowItem(watch('respuestaFinalCorrectoFlowID'), flowsList)
                        if (flow) setSelectedFlowCorrecto(flow)
                    } else {
                        setSelectedFlowCorrecto(null)
                    }
                } else if (key.startsWith('respuestaFinalIncorrecto')) {
                    if (watch('respuestaFinalInorrecto') === 'Texto/Imagen') {
                        if (!acc.flow_final_incorrecto) acc.flow_final_incorrecto = {};
                        const [prefix, subKey] = key.split('-');
                        acc.flow_final_incorrecto[subKey] = data[key];
                        setSelectedFlowIncorrecto(null)
                    } else if (watch('respuestaFinalInorrecto') === 'Respuesta del arbol') {
                        const flow = getFlowItem(watch('respuestaFinalCorrectoFlowID'), flowsList)
                        if (flow) setSelectedFlowIncorrecto(flow)
                    } else {
                        setSelectedFlowIncorrecto(null)
                    }
                }
            }
            return acc;
        }, {})
        return object
    }

    const onTagChange = (value, event, field) => {
        const acciones = finalizadosOpciones ?? []
        for (let i = 0; i < acciones.length; i++) {
            const accion = acciones[i]
            const existe = value.includes(accion.action);
            if (!existe && accion.required) {
                event.preventDefault()
                return
            }
        }
        field.onChange(value)
    }

    const style = {
        marginTop: 60,
        display: 'flex',
        gap: '30',
        overflow: 'auto',
        alignItems: 'flex-start',
        height: '93.2%'
    }

    return <>
        <HeaderSection title="Agregar - Modificar Formulario">
            <ButtonToolbar>
                <Button appearance="primary" type="button" onClick={metods.handleSubmit(onSubmit)} >Guardar</Button>
                <Button appearance="ghost" onClick={() => navigate(-1)}>Cancelar</Button>
            </ButtonToolbar>
        </HeaderSection>
        {(isFetching || isModificando || isLoading) && <Spiner />}
        <TypeFormItemSelector cerrar={() => setNewItemSteepSlector(false)} show={newItemSteepSlector} addNewItemSteep={(e) => addNewItemSteep(e)} />
        <div style={style}>
            <FormProvider {...metods}>
                <form className={styles.formulario} onSubmit={metods.handleSubmit(onSubmit)} >
                    <div className={styles.formBody}>
                        <div className={styles.formBodyLeft}>
                            <Stack justifyContent="space-between" alignItems="flex-start" spacing={20} style={{ margin: '0 0 20px 0' }}>
                                <Stack.Item basis={'80%'}>
                                    <InputGroup inside>
                                        <Controller
                                            name="nombreFormulario"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "El nombre del formulario es requerido"
                                                },
                                            }}
                                            control={control}
                                            render={({ field }) => (
                                                <Input id={field.name} value={field.value} onChange={value => field.onChange(value)} placeholder="Nombre del formulario" />
                                            )}
                                        />
                                        <InputGroup.Addon>
                                            <Whisper placement="top" speaker={
                                                <Tooltip> Nombre con el que identificarás este formulario en el arbol de respuestas</Tooltip>}>
                                                <InfoRoundIcon />
                                            </Whisper>
                                        </InputGroup.Addon>
                                    </InputGroup>
                                </Stack.Item>
                                <Stack.Item>
                                    <IconButton appearance="primary" color="green" icon={<PlusIcon />} onClick={() => setNewItemSteepSlector(true)}>Nueva Pregunta</IconButton>
                                </Stack.Item>
                            </Stack>
                            <Stack spacing={20}>
                                <Stack.Item basis={'100%'}>
                                    <label htmlFor="before"><strong>Acciones al completar formulario:</strong></label>
                                    <InputGroup inside>
                                        <Controller
                                            control={control}
                                            name="accionesFinales"
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'Selecciona la acción final que tendra este formulario'
                                                }
                                            }}
                                            render={({ field }) => (
                                                <TagPicker
                                                    id={field.name}
                                                    value={field.value}
                                                    data={finalizadosOpciones?.map(
                                                        item => ({ label: item.nombre, value: item.action })
                                                    )}
                                                    onChange={(value, event) => onTagChange(value, event, field)}
                                                    placeholder="Acción al completar"
                                                    style={{ width: '100%' }}
                                                    //placement="topStart"
                                                    cleanable={false}
                                                />
                                            )}
                                        />
                                        <InputGroup.Addon>
                                            <Whisper placement="top" speaker={
                                                <Tooltip> Selecciona la acción(nes) que se realizarán al completar este formulario</Tooltip>}>
                                                <InfoRoundIcon />
                                            </Whisper>
                                        </InputGroup.Addon>
                                    </InputGroup>
                                    <span className="spanError">{!!errors['accionesFinales'] && errors['accionesFinales'].message}</span>
                                </Stack.Item>
                            </Stack>
                            <h6 style={{ fontSize: 14, margin: '10px 0 5px 0' }}>Configuración de respuesta final</h6>
                            <Stack spacing={20} direction="row">
                                <Stack.Item basis={'50%'}>
                                    <p>Resultado correcto</p>
                                    <Controller
                                        control={control}
                                        name="respuestaFinalCorrecto"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Elige el tipo de respuesta final'
                                            }
                                        }}
                                        render={({ field }) => (
                                            <SelectPicker
                                                id={field.name}
                                                data={['Texto/Imagen', 'Respuesta del arbol', 'No hacer nada'].map(
                                                    f => ({ label: f, value: f })
                                                )}
                                                searchable={false}
                                                style={{ width: '100%' }}
                                                onChange={ value => field.onChange(value) }
                                                placeholder="Selecciona una opción"
                                                value={ field.value }
                                            />
                                        )}
                                    />
                                    <span className="spanError">{!!errors['respuestaFinalCorrecto'] && errors['respuestaFinalCorrecto'].message}</span>
                                </Stack.Item>
                                <Stack.Item basis={'50%'}>
                                    <p>Resultado incorrecto o error</p>
                                    <Controller
                                        control={control}
                                        name="respuestaFinalIncorrecto"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Elige el tipo de respuesta final'
                                            }
                                        }}
                                        render={({ field }) => (
                                            <SelectPicker
                                                id={field.name}
                                                data={['Texto/Imagen', 'Respuesta del arbol', 'No hacer nada'].map(
                                                    f => ({ label: f, value: f })
                                                )}
                                                searchable={false}
                                                style={{ width: '100%' }}
                                                onChange={ value => field.onChange(value) }
                                                placeholder="Selecciona una opción"
                                                value={ field.value }
                                            />
                                        )}
                                    />
                                    <span className="spanError">{!!errors['respuestaFinalIncorrecto'] && errors['respuestaFinalIncorrecto'].message}</span>
                                </Stack.Item>
                            </Stack>
                            <Stack spacing={20} direction="row">
                                <Stack.Item basis={'50%'}>
                                    {watch('respuestaFinalCorrecto') === 'Respuesta del arbol' && <><Controller
                                        control={control}
                                        name="respuestaFinalCorrectoFlowID"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Elige la respuesta final'
                                            }
                                        }}
                                        render={({ field }) => (
                                            <SelectPicker
                                                id={field.name}
                                                data={flowsList?.map(
                                                    f => ({ label: f.name ?? f.nombre, value: `${f.id}_${f.tipoRespuesta}` })
                                                )}
                                                searchable={false}
                                                style={{ width: '100%' }}
                                                onChange={ value => field.onChange(value) }
                                                placeholder="Elige la respuesta"
                                                value={ field.value }
                                            />
                                        )}
                                    />
                                    <span className="spanError">{!!errors['respuestaFinalCorrectoFlowID'] && errors['respuestaFinalCorrectoFlowID'].message}</span></>}
                                </Stack.Item>
                                <Stack.Item basis={'50%'}>
                                    {watch('respuestaFinalIncorrecto') === 'Respuesta del arbol' && <><Controller
                                        control={control}
                                        name="respuestaFinalIncorrectoFlowID"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Elige la respuesta final'
                                            }
                                        }}
                                        render={({ field }) => (
                                            <SelectPicker
                                                id={field.name}
                                                data={flowsList?.map(
                                                    f => ({ label: f.name ?? f.nombre, value: `${f.id}_${f.tipoRespuesta}` })
                                                )}
                                                searchable={false}
                                                style={{ width: '100%' }}
                                                onChange={ value => field.onChange(value) }
                                                placeholder="Elige la respuesta"
                                                value={ field.value }
                                            />
                                        )}
                                    />
                                    <span className="spanError">{!!errors['respuestaFinalIncorrectoFlowID'] && errors['respuestaFinalIncorrectoFlowID'].message}</span></>}
                                </Stack.Item>
                            </Stack>
                            <div className={styles.formItem_100} style={{ margin: '10px 0' }}>
                                <label htmlFor="descripcion"><strong>Descripcion:</strong></label>
                                <textarea placeholder="Escribe la descripción de este formulario" {...metods.register("descripcion", {
                                    required: {
                                        value: true,
                                        message: "Escribe la descripción de este formulario"
                                    },
                                })} rows={3}></textarea>
                                <span className={styles.errorForm}>{!!errors['descripcion'] && errors['descripcion'].message}</span>
                            </div>
                            <div className={styles.contSettingForm}>
                                {
                                    template.config?.map((element, index) => {
                                        return <FormItemElementConfig key={index} register={{
                                            ...metods.register(`xconfig-${element.clave}`, {
                                                required: {
                                                    value: element.requerido,
                                                    message: element.error ?? 'Campo requerido'
                                                }
                                            })
                                        }} errors={errors} element={element} width={48} />
                                    })
                                }
                                {
                                    finalizadosConfig?.map((element, index) => {
                                        return <FormItemElementConfig key={`fconfig-${index}`} register={{
                                            ...metods.register(`xconfig-${element.clave}`, {
                                                required: {
                                                    value: element.requerido,
                                                    message: element.error ?? 'Campo requerido'
                                                }
                                            })
                                        }} errors={errors} element={element} width={48} />
                                    })
                                }
                            </div>
                            <div className={styles.contFormItems}>
                                {
                                    elementos?.map((elemento, index) => <FormularioItem key={index} index={index} datos={elemento} unset={(eIndex) => unsetItemSteep(eIndex)} />)
                                }
                                { watch('respuestaFinalCorrecto') === 'Texto/Imagen' && <FormularioItemFin type="Correcto" />}
                                { watch('respuestaFinalIncorrecto') === 'Texto/Imagen' && <FormularioItemFin type="Incorrecto" />}
                            </div>
                        </div>
                        <div className={styles.formBodyRigth}>
                            <ContPrevFlows flows={[{
                                flow: JSON.stringify(previewList.flow),
                                tipoRespuesta: 'form',
                                flow_final_correcto: JSON.stringify(previewList.flow_final_correcto),
                                flow_final_incorrecto: JSON.stringify(previewList.flow_final_incorrecto)
                            },
                            ...(selectedFlowCorrecto !== null ? [selectedFlowCorrecto] : []),
                            ...(selectedFlowIncorrecto !== null ? [selectedFlowIncorrecto] : [])
                            ]} />
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    </>
}