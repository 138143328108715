
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

function useServiceValidation() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [ id, setId ] = useState(0)

    useEffect(() => {
        const serviceData = queryClient.getQueriesData('services')[0][1];
        if (serviceData && serviceData.length > 0) {
            const servicio = serviceData[0];
            const idBase64 = btoa(servicio.id.toString());
            setId(idBase64)
        } else {
            queryClient.removeQueries(['conversaciones', ''])
            navigate('/');
        }
    }, [queryClient, navigate]);

    return id
}

export default useServiceValidation;