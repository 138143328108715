import moment from "moment";
import useGetFlowResponses from "./useGetFlowResponses";

export const useDecodeMessageById = id => {
    const { flowsList } = useGetFlowResponses()
    const flow = flowsList.find(flow => flow.id === id)
    return flow
}

const useDecodeMessage = (message, isForm = false) => {

    try {
        const text = isForm ? (message?.text) : (JSON.parse(message?.text ?? '{}')?.texto ?? '')
        const buttons = (message) => {
            if (isForm && message?.tipoPregunta === 'buttons') {
                let buttons = []
                if (message.btn1 !== '') {
                    buttons[0] = { text: message?.btn1, id: 0 };
                }
                if (message.btn2 !== '') {
                    buttons[1] = { text: message?.btn2, id: 1 };
                }
                if (message.btn3 !== '') {
                    buttons[2] = { text: message?.btn3, id: 2 };
                }
                return buttons
            }
            return JSON.parse(message?.buttons ?? '[]') ?? []
        }
        const media = () => {
            if (!isForm) return JSON.parse(message?.media ?? '{}') ?? [];
            return message?.image !== '' ? {
                url: message?.image,
                name: ''
            } : []
        }
        const list = JSON.parse(message?.list ?? '{}') ?? []
        const beforeAfter = JSON.parse(message?.before_after ?? '{"before": 0, "after": 0}') ?? { before: 0, after: 0 }
        const contact = JSON.parse(message?.contact ?? '{}') ?? {}
        const tipoMessage = (message) => {
            if (isForm) {
                switch(message?.tipoPregunta) {
                    case 'buttons': return 'button'
                    case 'text': return message?.image !== '' ? 'image' : 'text'
                    default: return message?.tipoPregunta

                }
            } else {
                return message?.type
            }
        }
        if (message?.type === 'list') buttons[0] = { text: list?.button, id: 0 };
        return {
            tipo: tipoMessage(message),
            texto: text,
            estado: message?.estado ?? "read",
            buttons: buttons(message),
            media: media(message),
            list: list,
            contact: contact,
            before: beforeAfter.before,
            after: beforeAfter.after,
            fecha: {
                fecha: !!message?.fecha ? moment(message?.fecha).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
                hora: !!message?.fecha ? moment(message?.fecha).format('HH:mm') : moment().format('HH:mm')
            },
        }
    } catch (error) {
        return null
    }
}

export default useDecodeMessage