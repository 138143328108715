import React, { useContext, useEffect, useState } from "react"
import useFlowConfigsList from "../../hooks/bot/useFlowConfigsList"
import { useMutation, useQueryClient } from "react-query"
import { mutarDatos } from "../../providers/dataServer"
import { Message, useToaster } from "rsuite"
import Spiner from "../../components/Spiner"

const flowConfigContext = React.createContext()
const flowsSetConfigs = React.createContext()
const flowsKeyWordsSave = React.createContext()
const flowsKeyWordsCancel = React.createContext()

export const useFlowConfigContext = () => useContext(flowConfigContext)
export const useFlowsSetConfigs = () => useContext(flowsSetConfigs)
export const useFlowsKeyWordsSave = () => useContext(flowsKeyWordsSave)
export const useFlowsKeyWordsCancel = () => useContext(flowsKeyWordsCancel)

export const FlowConfigProvider = ({ children }) => {

    const queryClient = useQueryClient()
    const servicio = queryClient.getQueriesData('services')[0][1][0]
    const idBase64 = btoa(servicio.id.toString())

    const toaster = useToaster();

    const { keyWordsList, config, isLoadingFlows } = useFlowConfigsList()
    const [data, setData] = useState({ keyWordsList: keyWordsList, config: config, hayCambios: false })

    const { isLoading: guardando, mutate: guardarArbol } = useMutation(mutarDatos, {
        onSuccess: data => {
            let message;
            if (data.ok) {
                queryClient.removeQueries(['flowListConfig'])
                message = <Message showIcon type='success' closable>
                    <strong>Éxito!</strong> Se actualizó correctamente el arbol de respuestas.
                </Message>
            } else {
                message = <Message showIcon type='error' closable>
                    <strong>Error!</strong> Intenta mas tarde o contacta a soporte.
                </Message>
                console.error(data)
            }
            toaster.push(message, { placement: 'bottomEnd', duration: 5000 })
        }
    })

    useEffect(() => {
        if (typeof data?.keyWordsList === 'undefined') {
            setData({ keyWordsList: keyWordsList, config: config, hayCambios: false })
        }
    }, [keyWordsList])

    const setConfig = (element, type) => {
        if (type === 'keyword') {
            const newKeyWordsList = data.keyWordsList
            const indexToUpdate = newKeyWordsList.findIndex(k => k.keyWord === element?.keyWord);
            if (indexToUpdate !== -1) {
                newKeyWordsList[indexToUpdate].flowId = element?.flowId;
                newKeyWordsList[indexToUpdate].tipo = element?.tipo;
            } else {
                newKeyWordsList.push(element)
            }
            setData(old => ({ config: old.config, keyWordsList: newKeyWordsList, hayCambios: true }))
        } else if (type === 'config') {
            setData(old => ({ config: { activo: true, registro: element.registro }, keyWordsList: old.keyWordsList, hayCambios: true }))
        }
    }

    const saveKeyWords = () => {
        guardarArbol({ ruta: '/bot-whatsapp/tree/guardar', parametros: { s: idBase64, tree: JSON.stringify(data) } })
    }

    const revertirCambios = () => {
        const backupKeyWords = sessionStorage.getItem('keyWordsOrigin')
        if (backupKeyWords) {
            const backupKeyWordsList = JSON.parse(backupKeyWords)
            console.log(backupKeyWordsList.config)
            setData({ keyWordsList: backupKeyWordsList.keyWords, config: backupKeyWordsList.config, hayCambios: false })
        }
    }

    return <flowConfigContext.Provider value={data}>
        <flowsSetConfigs.Provider value={(element, type = 'keyword',) => setConfig(element, type)}>
            <flowsKeyWordsSave.Provider value={saveKeyWords}>
                <flowsKeyWordsCancel.Provider value={revertirCambios}>
                    {(guardando || isLoadingFlows) && <Spiner />}
                    {children}
                </flowsKeyWordsCancel.Provider>
            </flowsKeyWordsSave.Provider>
        </flowsSetConfigs.Provider>
    </flowConfigContext.Provider >
}