import { useFormContext } from 'react-hook-form';
import styles from '../../assets/css/formularios.module.css';
import SelectRequerido from './SelectRequerido';
import { useEffect, useState } from 'react';
import Icons from '../../components/Icons';

export default function FormularioItem({ unset, index, datos }) {

    const [showSelectRequeridoModal, setShowSelectRequeridoModal] = useState(false)
    const [requerido, setRequerido] = useState({
        id: 0,
        nombre: '',
        tipo: '',
        config: []
    })

    const { register, formState: { errors }, setValue } = useFormContext()
    const steepName = `steep_${index}`

    useEffect(() => {
        setValue(`${steepName}-tipoPregunta`, datos.tipoPregunta)
        setValue(`${steepName}-validacion`, datos.clavePregunta)
        setValue(`${steepName}-clavePregunta`, datos.clavePregunta)
        setValue(`${steepName}-esPlantilla`, datos.esPlantilla)
        setValue(`${steepName}-plantillaGroup`, datos.plantillaGroup)
        if (typeof datos.requerido !== 'undefined') {
            setearRequrido(datos.requerido)
        }
    }, [setValue, steepName, datos])

    function setearRequrido(data) {
        setRequerido(data)
        setShowSelectRequeridoModal(false)
        setValue(`${steepName}-setTipoReq`, true)
        setValue(`${steepName}-clavePregunta`, data.clave)
        setValue(`${steepName}-validacion`, data.clave)
        setValue(`${steepName}-requerido`, data)
    }

    const mapTipoShow = {
        'text': 'Texto',
        'buttons': 'Botones',
        'buttonApi': 'Botones',
        'textApi': 'Texto',
    }

    const isValidImageUrl = async (url) => {
        try {
            const response = await fetch(url, { method: 'HEAD' });
            const contentType = response.headers.get('Content-Type');
            return response.ok && contentType.startsWith('image/');
        } catch (error) {
            return false;
        }
    };

    return <div className={styles.contFormularioSteep}>
        {showSelectRequeridoModal && <SelectRequerido cerrar={() => setShowSelectRequeridoModal(false)} setRequerido={(data) => setearRequrido(data)} />}
        {!datos.esPlantilla && <button type='button' className={styles.btnDelItemFrom} onClick={() => unset(index)}>X</button>}
        <div className={styles.formItemSteepHeader}>
            <div className={styles.titleSteep}>
                <div className={styles.number}>{index + 1}</div>
                <div className={`${styles.formItem} ${styles.formItem_100}`}>
                    <input type='text' className={styles.inputTituloSteep} defaultValue={datos.titulo ?? (datos.nombre ?? `Pregunta ${index + 1}`)} placeholder="Nombre de pregunta" {...register(`${steepName}-nombre`, {
                        required: {
                            value: true,
                            message: 'Escribe el nombre de esta pregunta',
                        },
                        maxLength: 50
                    })} />
                    <span className={styles.errorForm}>{!!errors[`${steepName}-nombre`] && errors[`${steepName}-nombre`].message}</span>
                </div>
                <Icons req={'edit'} fill='var(--blanco)' />
            </div>
            <div className={styles.typeSteep}>
                <div className={styles.typeSteepLine}></div>
                <p>{mapTipoShow[datos.tipoPregunta]}</p>
            </div>
        </div>
        <div className={styles.formItemSteepBody}>
            <div className={`${styles.formItem} ${styles.formItem_100}`} style={{ marginBottom: 15 }}>
                <input defaultValue={datos.image} type='text' placeholder="Url imagen (Opcional)" {...register(`${steepName}-image`, {
                    validate: async value => value !== '' ? (await isValidImageUrl(value) || 'Este enlace no esta soportado') : true
                })} />
                <span className={styles.errorForm}>{!!errors[`${steepName}-image`] && errors[`${steepName}-image`].message}</span>
            </div>
            <div className={styles.formItem_100}>
                <textarea defaultValue={datos.text} placeholder="Escribe el requerimiento o pregunta" {...register(`${steepName}-text`, {
                    required: {
                        value: true,
                        message: "Escribe el requerimiento o pregunta"
                    },
                })} rows={6}></textarea>
                <span className={styles.errorForm}>{!!errors[`${steepName}-text`] && errors[`${steepName}-text`].message}</span>
            </div>
            {!datos.esPlantilla && <div className={styles.contRequeridos}>
                <p className={styles.typeReqText}>{datos.tipoPregunta === 'text' ? 'Requerido' : 'Botones'}:</p>
                {datos.tipoPregunta === 'text' && <div className={styles.formItem} style={{ width: '70%' }}>
                    <button type='button' className={styles.btnButtonReq} onClick={() => setShowSelectRequeridoModal(true)}>{requerido.id > 0 ? requerido.nombre : 'Requerido para esta pregunta'}</button>
                    <input type='checkbox' defaultChecked={requerido.id > 0} hidden {...register(`${steepName}-setTipoReq`, {
                        validate: value => value || 'Elige el tipo de validación para esta pregunta'
                    })} />
                    <span className={styles.errorForm}>{!!errors[`${steepName}-setTipoReq`] && errors[`${steepName}-setTipoReq`].message}</span>
                </div>}
            </div>}
            {(datos.tipoPregunta === 'text' && !datos.esPlantilla) && <div className={styles.contConfigReq} style={{ width: '40%' }}>
                {requerido?.config?.map((conf, index) => <div key={index} className={styles.formItem}>
                    <input type='text' defaultValue={datos[`req2${conf.clave}`] ?? ''} {...register(`${steepName}-req2${conf.clave}`, {
                        required: {
                            value: true,
                            message: conf.error
                        },
                    })} placeholder={conf.placeholder} />
                    <span className={styles.errorForm}>{!!errors[`${steepName}-${conf.clave}`] && errors[`${steepName}-${conf.clave}`].message}</span>
                </div>)}
            </div>}
            {((datos.tipoPregunta === 'buttons' && !datos.esPlantilla) || (datos.tipoPregunta === "buttonApi")) && <div className={styles.contButtonsItemSteep}>
                <div className={styles.formItem}>
                    <input type='text' defaultValue={datos.btn1} {...register(`${steepName}-btn1`, {
                        required: {
                            value: true,
                            message: 'Texto del botón es obligatorio'
                        },
                        minLength: {
                            value: 2,
                            message: 'El botón debe tener mínimo 2 caracteres'
                        },
                        maxLength: {
                            value: 20,
                            message: 'El botón debe tener máximo 20 caracteres'
                        }
                    })} placeholder='Texto Boton 1 (Obligatorio)' />
                    <span className={styles.errorForm}>{!!errors[`${steepName}-btn1`] && errors[`${steepName}-btn1`].message}</span>
                </div>
                <div className={styles.formItem}>
                    <input type='text' defaultValue={datos.btn2} {...register(`${steepName}-btn2`, {
                        required: {
                            value: false,
                            message: 'Texto del botón es obligatorio'
                        },
                        minLength: {
                            value: 2,
                            message: 'El botón debe tener mínimo 2 caracteres'
                        },
                        maxLength: {
                            value: 20,
                            message: 'El botón debe tener máximo 20 caracteres'
                        }
                    })} placeholder='Texto Boton 2 (Opcional)' />
                    <span className={styles.errorForm}>{!!errors[`${steepName}-btn2`] && errors[`${steepName}-btn2`].message}</span>
                </div>
                <div className={styles.formItem}>
                    <input type='text' defaultValue={datos.btn3} {...register(`${steepName}-btn3`, {
                        required: {
                            value: false,
                            message: 'Texto del botón es obligatorio'
                        },
                        minLength: {
                            value: 2,
                            message: 'El botón debe tener mínimo 2 caracteres'
                        },
                        maxLength: {
                            value: 20,
                            message: 'El botón debe tener máximo 20 caracteres'
                        }
                    })} placeholder='Texto Boton 3 (Opcional)' />
                    <span className={styles.errorForm}>{!!errors[`${steepName}-btn3`] && errors[`${steepName}-btn3`].message}</span>
                </div>
            </div>}
            {datos.esPlantilla && <p className={styles.formItemDescripcionText}>{datos.descripcion}</p>}
        </div>
    </div>
}