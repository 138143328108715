import GlobalModalContainer from "../../components/GlobalModalContainer"
import styles from '../../assets/css/formularios.module.css'
import { useQuery } from "react-query";
import { getDatos } from "../../providers/dataServer";

const FormTemplateSelect = ({ show, cerrar, formSelected }) => {

    const { data: plantillas, isLoading } = useQuery(["formTemplates"], () => getDatos('/bot-whatsapp/getFormTemplate'));

    return <GlobalModalContainer show={ show } title={ 'Elige el tipo de formulario' } cerrar={ cerrar } isLoading={ isLoading } textLoading={ 'Recuperando formularios...' }>
        <div className={ styles.contTypeItemOptions }>
            <div className={ styles.typeItemOption } onClick={ () => formSelected(null) }>
                <h4>Formulario en blanco</h4>
                <p>Crea tu formulario desde cero</p>
            </div>
        {
            plantillas?.map((plantilla, index) => <div key={ index } className={ styles.typeItemOption } onClick={ () => formSelected(plantilla) }>
                <h4>{plantilla.nombre}</h4>
                <p>{plantilla.descripcion}</p>
            </div>)
        }
        </div>
    </GlobalModalContainer>
}

export default FormTemplateSelect