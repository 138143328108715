import HeaderSection from "../components/HeaderSection";
import styles from '../assets/css/arbolRespuestas.module.css'
import AnswerConfigItem from "./components/AnswerConfigItem";
import { useFlowConfigContext, useFlowsKeyWordsSave, useFlowsKeyWordsCancel, useFlowsSetConfigs } from "./providers/FlowConfigProvider";
import { Accordion, Button, ButtonToolbar, Stack, Toggle } from "rsuite";
import Gear from '@rsuite/icons/legacy/Gear';

const ArbolRespuestas = ({ }) => {

    const guardarCambios = useFlowsKeyWordsSave()
    const cancelar = useFlowsKeyWordsCancel()
    const { hayCambios, config } =  useFlowConfigContext()
    const setearConfig =  useFlowsSetConfigs()

    return <>
        <HeaderSection title="Arbol de respuestas">
            { hayCambios && <ButtonToolbar>
                <Button appearance="primary" onClick={guardarCambios}>Guardar Cambios</Button>
                <Button appearance="ghost" onClick={cancelar}>Cancelar</Button>
            </ButtonToolbar>}
        </HeaderSection>
        <Accordion style={{ marginTop: 60 }}>
            <Accordion.Panel
                defaultExpanded={false}
                header={<p style={{ fontSize: 12 }}>Configuraciones Adicionales</p>}
                eventKey={1}
                caretAs={Gear}
                style={{ backgroundColor: "white" }}
            >
                <Stack>
                    <Toggle size="sm" checked={ config.registro } onChange={ e => setearConfig({ registro: e }, 'config') }>Solicitar nombre de contacto antes de continuar</Toggle>
                </Stack>
            </Accordion.Panel>
        </Accordion>
        <div className={styles.contArbol}>
            <AnswerConfigItem titulo={'Respuesta inicial'} eventKey={'bienvenida'} />
            {config.registro && <AnswerConfigItem titulo={'Respuesta inicial para registro'} eventKey={'bienvenida_n_r'} />}
            <AnswerConfigItem titulo={'Cuando no se encuentra una respuesta'} eventKey={'no_info'} />
        </div>
    </>
}

export default ArbolRespuestas;