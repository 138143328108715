import { Routes, Route, Navigate } from 'react-router-dom';
import './assets/css/App.css';
import './assets/css/NewApp.css';
import './assets/css/media-querys.css';
import BodyChatsDisplay from './bot/BodyChatsDisplay';
import { ProtectedRoutes } from './components/ProtectedRoutes';
import LogReg from './pages/LogReg';
import Dashboard from './pages/Dashboard';
import BotWhatsapp from './pages/BotWhatsapp';
import ListaRespuestas from './bot/ListaRespuestas';
import AgregarModificarRespuesta from './bot/AgregarModificarRespuesta';
import AppInit from './pages/AppInit';
import Formularios from './bot/Formularios';
import AgregarModificarFormulario from './bot/AgregarModificarFormulario';
import TextosActivadores from './bot/TextosActivadores';
import useAuth from './hooks/useAuth';
import ArbolRespuestas from './bot/ArbolRespuestas';
import 'rsuite/dist/rsuite.min.css';

function App() {

  const { authenticated: usuarioLogeado } = useAuth();

  return (<>
      <Routes>
        <Route path="/" element={ usuarioLogeado ? <ProtectedRoutes isAuthenticated={ usuarioLogeado }><AppInit/></ProtectedRoutes> : <Navigate replace to="/login"/> }>
          <Route index element={ <Dashboard /> }/>
          <Route path='/bot-whatsapp/:idService' element={<ProtectedRoutes isAuthenticated={ usuarioLogeado }><BotWhatsapp/></ProtectedRoutes>}>
            <Route index element={<BodyChatsDisplay/>} />
            <Route path='arbol-del-bot' element={ <ArbolRespuestas/> } />
            <Route path='lista-de-respuestas' element={ <ListaRespuestas/> } />
            <Route path='agregar-modificar-respuesta' element={ <AgregarModificarRespuesta/> } />
            <Route path='formularios' element={ <Formularios/> } />
            <Route path='textos-activadores' element={ <TextosActivadores/> } />
            <Route path='agregar-modificar-formulario' element={ <AgregarModificarFormulario/> } />
          </Route>
        </Route>
        <Route path='login' element={ usuarioLogeado ? <Navigate replace to="/"/> : <LogReg/> } />
        {/*<Route path='/inicio' element={
          <ProtectedRoutes><Dashboard/></ProtectedRoutes>
        }></Route>

        <Route path='/mass-mail' element={
          <ProtectedRoutes>
            <MassMailer/>
          </ProtectedRoutes>
        } />
      <Route path='*' element={ <Navigate replace to="/"/> } />*/}
      <Route path='*' element={ <Navigate replace to="/"/> } />
      </Routes>
    </>);
}

export default App;
