import { IconButton, SelectPicker, Stack } from 'rsuite'
import styles from '../../assets/css/answerConfig.module.css'
import useGetFlowResponses from '../../hooks/bot/useGetFlowResponses'
import Eye from '@rsuite/icons/legacy/Eye';
import { useEffect, useState, useMemo } from 'react';
import ModalPrevFlows from './ModalPrevFlows';
import { Accordion } from 'rsuite';
import { FaAngleDoubleDown } from 'react-icons/fa';
import { useFlowConfigContext, useFlowsSetConfigs } from '../providers/FlowConfigProvider';

function AnswerHijos({ flowPadre, flowFormResponse }) {
    const getColorBg = () => {
        let difference = 255 - 215;
        let rand = Math.random();
        rand = Math.floor(rand * difference);
        rand = rand + 235;
        return rand;
    };

    const colorHijo = useMemo(() => () => `rgb(${getColorBg()} ${getColorBg()} ${getColorBg()})`, []);

    const contRespuestas = useMemo(() => {
        let result = <></>;
        let type = flowPadre?.type ?? '';
        if (flowPadre?.tipoRespuesta === 'form') {
            type = 'form';
        }

        switch (type) {
            case 'list':
                const lista = JSON.parse(flowPadre.list);
                result = (
                    <Stack direction='row' spacing={5}>
                        {lista.sections?.map((section) => 
                            section.rows?.map((s) => (
                                <AnswerConfigItem
                                    key={s.id}
                                    eventKey={s.id}
                                    titulo={s.title}
                                    color={colorHijo()}
                                />
                            ))
                        )}
                    </Stack>
                );
                break;
            case 'button':
                const buttons = JSON.parse(flowPadre.buttons);
                result = (
                    <Stack direction='row' spacing={5}>
                        {buttons?.map((b) => (
                            <AnswerConfigItem
                                eventKey={b.id}
                                key={b.id}
                                titulo={b.text}
                                color={colorHijo()}
                            />
                        ))}
                    </Stack>
                );
                break;
            case 'text':
                const requerido = JSON.parse(flowPadre.requerido);
                result = <AnswerConfigItem eventKey={requerido.req_texto} titulo={flowPadre.name} color={colorHijo()} />;
                break;
            case 'form':
                let correcto = <></>;
                let error = <></>;
                if (flowFormResponse.correcto) {
                    let contenido = <></>;
                    switch (flowFormResponse.correcto.type) {
                        case 'list':
                            const listaCorrecto = JSON.parse(flowFormResponse.correcto.list);
                            contenido = (
                                <>
                                    {listaCorrecto.sections?.map((section) =>
                                        section.rows?.map((s) => (
                                            <AnswerConfigItem
                                                key={s.id}
                                                eventKey={s.id}
                                                titulo={s.title}
                                                color={colorHijo()}
                                            />
                                        ))
                                    )}
                                </>
                            );
                            break;
                        case 'button':
                            const buttonsCorrecto = JSON.parse(flowFormResponse.correcto.buttons);
                            contenido = (
                                <>
                                    {buttonsCorrecto?.map((b) => (
                                        <AnswerConfigItem
                                            eventKey={b.id}
                                            key={b.id}
                                            titulo={b.text}
                                            color={colorHijo()}
                                        />
                                    ))}
                                </>
                            );
                            break;
                        case 'text':
                            const requeridoCorrecto = JSON.parse(flowFormResponse.correcto.requerido);
                            contenido = <AnswerConfigItem eventKey={requeridoCorrecto.req_texto} titulo={flowFormResponse.correcto.name} color={colorHijo()} />;
                            break;
                        default:
                            contenido = <>Aqui termina esta rama</>;
                    }
                    correcto = (
                        <Stack.Item>
                            <p className={styles.titleFormCorrect}>Si todo es correcto en el formulario:</p>
                            <Stack direction='row' spacing={5} className={styles.contRespuestasForm}>
                                {contenido}
                            </Stack>
                        </Stack.Item>
                    );
                }
                if (flowFormResponse.error) {
                    let contenido = <></>;
                    switch (flowFormResponse.error.type) {
                        case 'list':
                            const listaError = JSON.parse(flowFormResponse.error.list);
                            contenido = (
                                <>
                                    {listaError.sections?.map((section) =>
                                        section.rows?.map((s) => (
                                            <AnswerConfigItem
                                                key={s.id}
                                                eventKey={s.id}
                                                titulo={s.title}
                                                color={colorHijo()}
                                            />
                                        ))
                                    )}
                                </>
                            );
                            break;
                        case 'button':
                            const buttonsError = JSON.parse(flowFormResponse.error.buttons);
                            contenido = (
                                <>
                                    {buttonsError?.map((b) => (
                                        <AnswerConfigItem
                                            eventKey={b.id}
                                            key={b.id}
                                            titulo={b.text}
                                            color={colorHijo()}
                                        />
                                    ))}
                                </>
                            );
                            break;
                        case 'text':
                            const requeridoError = JSON.parse(flowFormResponse.error.requerido);
                            contenido = <AnswerConfigItem eventKey={requeridoError.req_texto} titulo={flowFormResponse.error.name} color={colorHijo()} />;
                            break;
                        default:
                            contenido = <>Aqui termina esta rama</>;
                    }
                    error = (
                        <Stack.Item>
                            <p className={styles.titleFormCorrect}>Si hay un error en el formulario:</p>
                            <Stack direction='row' spacing={5} className={styles.contRespuestasForm}>
                                {contenido}
                            </Stack>
                        </Stack.Item>
                    );
                }
                result = (
                    <Stack direction='row' spacing={5}>
                        {correcto}
                        {error}
                    </Stack>
                );
                break;
            default:
                result = <>Aqui termina esta rama</>;
        }
        return result;
    }, [flowPadre, flowFormResponse, colorHijo]);

    return contRespuestas;
}

const AnswerConfigItem = ({ titulo, eventKey = null, color = 'white' }) => {
    const { flowsList, getFlowItem } = useGetFlowResponses();
    const keyWords = useFlowConfigContext();
    const [selectedFlow, setSelectedFlow] = useState(null);
    const [mostrarPreview, setMostrarPreview] = useState(false);
    const [tipoRespuesta, setTipoRespuesta] = useState('Ninguna');
    const [tieneHijos, setTieneHijos] = useState(false);
    const setearkeyWord = useFlowsSetConfigs();
    const [flowFormResponse, setFlowFormResponse] = useState(null);
    const [expandedAccordion, setExpandedAccordion] = useState(false);

    useEffect(() => {
        if (selectedFlow) {
            const tipo = selectedFlow.type ?? selectedFlow.tipoRespuesta;
            const requerido = selectedFlow.requerido ? JSON.parse(selectedFlow.requerido).is_req === 1 : false;
            setTipoRespuesta(tipo);
            let formTieneHijos = false;
            if (selectedFlow.tipoRespuesta === 'form') {
                let flowFinal = JSON.parse(selectedFlow.flow_final);
                if (flowFinal.correcto.value === 'Respuesta del arbol' || flowFinal.error.value === 'Respuesta del arbol') {
                    let responsesForm = { correcto: null, error: null };
                    if (flowFinal.correcto.value === 'Respuesta del arbol') {
                        const flow = getFlowItem(flowFinal.correcto.flow, flowsList);
                        if (flow) responsesForm.correcto = flow;
                        formTieneHijos = true;
                    }
                    if (flowFinal.error.value === 'Respuesta del arbol') {
                        const flow = getFlowItem(flowFinal.error.flow, flowsList);
                        if (flow) responsesForm.error = flow;
                        formTieneHijos = true;
                    }
                    if (formTieneHijos) setFlowFormResponse(responsesForm);
                } else {
                    if (flowFormResponse !== null) setFlowFormResponse(null);
                }
            }
            setTieneHijos((tipo === 'button' || tipo === 'list') || requerido || formTieneHijos);
        } else {
            setTieneHijos(false);
        }
    }, [selectedFlow, flowsList, getFlowItem]);

    useEffect(() => {
        if (eventKey && keyWords && flowsList) {
            const keyWord = keyWords?.keyWordsList?.find((k) => k.keyWord === eventKey);
            const flow = getFlowItem(`${keyWord?.flowId}_${keyWord?.tipo}`, flowsList);
            if (flow) setSelectedFlow(flow);
        }
    }, [eventKey, keyWords, flowsList, getFlowItem]);

    const asignarFlow = useMemo(() => (value) => {
        const flowSelected = getFlowItem(value, flowsList);
        setearkeyWord({ keyWord: eventKey, flowId: flowSelected.id, tipo: flowSelected.tipoRespuesta });
        setSelectedFlow(flowSelected); // Asegurar que se actualiza `selectedFlow`
    }, [getFlowItem, flowsList, setearkeyWord, eventKey]);

    const unsetFlow = useMemo(() => () => {
        setTipoRespuesta('Ninguna');
        setearkeyWord({ keyWord: eventKey, flowId: 0, tipo: '' });
        setSelectedFlow(null);
    }, [setearkeyWord, eventKey]);

    const cerrarPreview = () => {
        setMostrarPreview(false);
    };

    const mostrarPrev = () => {
        if (selectedFlow) setMostrarPreview(true);
    };

    return (
        <div className={styles.contAnswerConfig} style={{ backgroundColor: color }}>
            {mostrarPreview && <ModalPrevFlows flows={[selectedFlow]} cerrar={cerrarPreview} />}
            <p className={styles.titleAnswer}>{titulo}</p>
            <Stack direction='row' spacing={5} alignItems='center' style={{ width: '100%' }}>
                <SelectPicker
                    data={flowsList?.map(
                        f => ({ label: f.name ?? f.nombre, value: `${f.id}_${f.tipoRespuesta}` })
                    )}
                    style={{ width: 170 }}
                    placeholder="Respuesta a esta pregunta"
                    size="xs"
                    onSelect={asignarFlow}
                    onClean={unsetFlow}
                    value={selectedFlow ? `${selectedFlow?.id}_${selectedFlow?.tipoRespuesta}` : ''}
                />
                <IconButton icon={<Eye />} size="xs" onClick={mostrarPrev} />
            </Stack>
            <Accordion onSelect={e => setExpandedAccordion(s => !s)} >
                <Accordion.Panel
                    defaultExpanded={false}
                    header={<p style={{ fontSize: 12 }}>{`Tipo: ${tipoRespuesta}`}</p>}
                    eventKey={1}
                    caretAs={FaAngleDoubleDown}
                    expanded={expandedAccordion}
                >
                    {expandedAccordion && (tieneHijos ? <AnswerHijos flowPadre={selectedFlow} flowFormResponse={flowFormResponse} /> : <p style={{ fontSize: 10, maxWidth: '150px' }}>Aqui termina esta rama para la respuesta seleccionada</p>)}
                </Accordion.Panel>
            </Accordion>
        </div>
    );
};

export default AnswerConfigItem;
