import GlobalModalContainer from "../../components/GlobalModalContainer"
import styles from '../../assets/css/formularios.module.css'

const TypeFormItemSelector = ({ show, cerrar, addNewItemSteep }) => {

    function setearTipoSteep(tipo) {
        switch (tipo) {
            case 'text':
                addNewItemSteep({
                    tipoPregunta: "text",
                    esPlantilla: false,
                })
                break
            case 'buttons':
                addNewItemSteep({
                    tipoPregunta: "buttons",
                    esPlantilla: false,
                })
                break
        }
    }

    return <GlobalModalContainer show={ show } title={ 'Elige el tipo de pregunta' } cerrar={ cerrar }>
        <div className={ styles.contTypeItemOptions }>
            <div className={ styles.typeItemOption } onClick={ () => setearTipoSteep('text') }>
                <h4>Texto</h4>
                <p>La pregunta será un texto simple, puedes especificar instrucciones de que responder</p>
            </div>
            <div className={ styles.typeItemOption } onClick={ () => setearTipoSteep('buttons') }>
                <h4>Botones</h4>
                <p>Se enviarán hasta 3 botones con opciones predefinidas para que elija el usuario</p>
            </div>
        </div>
    </GlobalModalContainer>
}

export default TypeFormItemSelector