import styles from '../assets/css/global.module.css'
import { cloneElement } from 'react'
import { LoadingOval } from './Loadings'

const GlobalModalContainer = ({ children, show=false, cerrar, setear, title, descripcion, width=700, isLoading=false, textLoading='Cargando...' }) => {

    const styleBody = {
        width: width
    }

    return show && <div className={ styles.contModal }>
    <div className={ styles.modal }>
        <button className={ styles.btnCerrarModal } type='button' onClick={ cerrar }>Cerrar</button>
        <h3>{ title }</h3>
        <p>{ descripcion }</p>
        <div className={ styles.modalBody } style={ styleBody }>
            { isLoading ? <LoadingOval show={ isLoading } text={ textLoading } /> : (children && cloneElement(children, { setear })) }
        </div>
    </div>
</div>
}

export default GlobalModalContainer