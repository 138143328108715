import { useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import moment from "moment/moment";
import imagenBot from '../assets/images/msg-bot.png';
import Vistos from "./Vistos";
import { ListView, Location, Image, Video, Sticker, Audio, Document, Contacts } from "./MessageType";
import Icons from "../components/Icons";
import ModalBox from "../components/ModalBox";
import { urlServer } from "../config/apiConfig";
import { customSwall } from "../components/CustomSwal";

function texto(texto) {
    let nuevoTexto = texto
    let textMatchs = nuevoTexto.match(/\*.+[^\n ]\*/g)
    if (!!textMatchs) {
        textMatchs.forEach(element => {
            let textLimpio = element.substring(1, element.length - 1)
            nuevoTexto = nuevoTexto.replace(element, '<b>' + textLimpio + '</b>')
        });
    }
    return nuevoTexto
}

function ChatCliente({ mensaje }) {

    let media = <></>
    let noBackground = '';

    switch(mensaje.tipo) {
        case 'sticker':
            media = <Sticker img={ mensaje.archivo }/>
            noBackground = ' no-background';
            break
        case 'image':
            media = <Image img={ mensaje.archivo }/>
            break
        case 'audio':
            media = <Audio media={ mensaje.archivo }/>
            break
        case 'video':
            media = <Video video={ mensaje.archivo }/>
            break
        case 'document':
            media = <Document file={ mensaje.archivo } nombre={ mensaje.nombre_archivo }/>
            break
        case 'location':
            media = <Location latitude={ mensaje.latitude } longitude={ mensaje.longitude } />
            break
        case 'contacts':
            media = <Contacts cname={ mensaje.cname } cnumero={ mensaje.cnumero } />
            break
    }

    return (
        <li className="con-message-other-message">
            <div className={ `cont-message other other-message${ noBackground }` }>
                { media }
                <div className="new-line" dangerouslySetInnerHTML={{__html: texto(mensaje.texto)}} />
                <div className="cont-footer-msgs">
                    <span>{ moment(mensaje.fecha).format('HH:mm') }</span>
                </div>
            </div>
        </li>
    );
}

function ChatUser({ mensaje, mostrarList }) {
    //console.log(mensaje)

    let media = <></>
    let maxW = mensaje.asesor === 'Bot' ? ' new-width' : ''
    let file = !!mensaje.archivo ? `{"type": ${ mensaje.tipo },"name": ${ mensaje.archivo },"url": ""}` : mensaje.media

    switch(mensaje.tipo) {
        case 'sticker':
            media = <Sticker img={ file }/>
            break
        case 'image':
            media = <Image img={ file }/>
            break
        case 'audio':
            media = <Audio media={ file }/>
            break
        case 'video':
            media = <Video video={ file }/>
            break
        case 'document':
            media = <Document file={ file } nombre={ mensaje.nombre_archivo }/>
            break
        case 'location':
            media = <Location latitude={ mensaje.latitude } longitude={ mensaje.longitude } />
            break
        case 'contacts':
            media = <Contacts cname={ mensaje.cname } cnumero={ mensaje.cnumero } />
            break
        case 'button':
            let med = JSON.parse(mensaje.media)
            if (!!med.type) {
                if (med.type == 'image') {
                    media = <Image img={ file }/>
                }
                if (med.type == 'video') {
                    media = <Video video={ file }/>
                }
            } else {
                if (mensaje.archivo !== '') {
                    media = <Image img={ mensaje.archivo }/>
                }
            }
            break
        case 'list':
            //console.log(mensaje)
            break
    }

    function Buttons({ buttons, mostrarList }) {
        return(<div className='buttons-msj' onClick={ mensaje.tipo === 'list' ? mostrarList : null }>
            { mensaje.tipo === 'button' ?
                buttons.map((button, index) => {
                    return <p key={ `b_${index+1}` } >{ !!button.text ? button.text : button }</p>
                }) : <p className='list-element'><Icons req={ 'list' } fill="rgb(0, 157, 226)" /> { buttons.button }</p>
            }
        </div>)
    }

    function isEmpty(obj) {
        for (const prop in obj) {
          if (Object.hasOwn(obj, prop)) {
            return false;
          }
        }
      
        return true;
    }

    return (
        <li className="con-message-my-message">
            <div className={ `cont-message` }>
                <div className={`cont-message-content my-message${ maxW }`}>
                    { media }
                    <div className="new-line" dangerouslySetInnerHTML={{__html: texto(mensaje.texto) }} />
                    <div className="cont-footer-msgs">
                        <span>{ moment(mensaje.fecha).format('HH:mm') }</span>
                        <Vistos estado={mensaje.estado} />
                    </div>
                </div>
                { (!isEmpty(JSON.parse(mensaje.buttons)) || !isEmpty(JSON.parse(mensaje.list))) && 
                    <Buttons buttons={ JSON.parse(mensaje.tipo === 'button' ? mensaje.buttons : mensaje.list) } mostrarList={ mostrarList }/>
                }
            </div>
        </li>
    );
}

function HeaderChats({ contactIcon, nombre, celular, mostrarContactos }) {
    return (
        <div className="cont-der-chat-header">
            <div className="cont-der-header-info">
                <img src={contactIcon} alt="foto de perfil"/>
                <div className="cont-contacto-info">
                    <h3>{ nombre }</h3>
                    <p>{ celular }</p>
                </div>
            </div>
            {
                isMobile &&
                <svg onClick={ mostrarContactos } xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
            }
        </div>
    );
}

function CartDate({ date }) {
    return(
        <li className="con-message-fecha">
            <p>{ date }</p>
        </li>
    );
}

function Burbuja({ agent, msj, mostrarList}) {
    return((agent === 1) ? <ChatUser mensaje={msj} mostrarList={ mostrarList } /> : <ChatCliente mensaje={msj}/>);
}

function ChatContainer({ contactIcon, mostrarchats, mensajes, contacto, mostrarContactos }) {
    const [ flowList, setFlowlist ] = useState([])
    const [ showModalFlows, setShowModalFlows ] = useState(false)
    const [ currentModal, setCurrentModal ] = useState(null);
    const [ mostarModalBox, setMostarModalBox ] = useState(false);
    const navigate = useNavigate();
    const queryClient = useQueryClient()
    let divChats = <></>;


    useEffect( () =>  {
        const fetchData = async () => await queryClient.getQueryData('flowsList')
        fetchData().then((data)=> setFlowlist(data) ).catch(console.error);
    } , [] )

    function fechaMensaje(date) {
        let fechaHoy = moment().format('DD/MM/YY');
        let fechaMens = moment(date).format('DD/MM/YY');
        if (fechaHoy === fechaMens) {
            return 'HOY';
        } else {
            if ((Date.now() - (Date.parse(date))) > 172800000) {
                return fechaMens;
            } else {
                return 'AYER';
            }
        }
    }

    function closeModalBox() {
        setMostarModalBox(false);
    }

    function funcMostrarModalBox(lista) {
        setMostarModalBox(true);
        setCurrentModal(lista);
    }

    function enviarMensaje(flowId, celular) {
        //console.log('mensaje enviado ' + flowId + ' dd ' + celular)
        const user = JSON.parse(localStorage.getItem('userData'))
        let formData = new FormData();
        formData.append('token', user.token);
        formData.append('idFlow', flowId);
        formData.append('celular', celular);
        fetch(`${ urlServer }/bot-whatsapp/sendFlowToNumber`, {
            method: 'POST',
            body: formData,
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.ok) {
                customSwall.fire({
                    icon: 'success',
                    title: `Enviado`,
                    text: `Mensaje enviado correctamente`,
                    showConfirmButton: true,
                })
            } else {
                customSwall.fire({
                    icon: 'error',
                    title: 'Error',
                    text: !!data.error ? data.error : 'ocurrio un error',
                    showConfirmButton: true,
                })
            }
        })
        .catch((error) => console.log(error))
    }

    if (mostrarchats) {
        let intervalo = moment(mensajes[0].fecha).format('DD/MM/YY');
        const celular = mensajes[0].celular;

        function cambiarIntervalo(interv) {
            intervalo = interv;
        }

        divChats = <>
            <HeaderChats contactIcon={ contactIcon } nombre={ contacto } celular={ celular } mostrarContactos={ mostrarContactos } />
            <ul className="cont-chat-history">
                {
                    mensajes?.map((msj) => {
                        return (<>
                            {(intervalo !== moment(msj.fecha).format('DD/MM/YY')) && <CartDate key={ 'fech_' + msj.id } date={ intervalo }/>}
                            <Burbuja agent={ msj.agent } key={ msj.id } msj={ msj } mostrarList={ () => funcMostrarModalBox(msj.list) } />
                            { cambiarIntervalo(moment(msj.fecha).format('DD/MM/YY')) }
                        </>);
                    })
                }
                <CartDate date={ fechaMensaje(mensajes[mensajes.length - 1].fecha) }/>
            </ul>
            <div className="cont-der-inputs-msgs">
                <div className="der-emogis">
                    <span data-testid="smiley" data-icon="smiley" className=""><svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" className="ekdr8vow dhq51u3o" version="1.1" x="0px" y="0px" enableBackground="new 0 0 24 24" ><path fill="#646464" d="M9.153,11.603c0.795,0,1.439-0.879,1.439-1.962S9.948,7.679,9.153,7.679 S7.714,8.558,7.714,9.641S8.358,11.603,9.153,11.603z M5.949,12.965c-0.026-0.307-0.131,5.218,6.063,5.551 c6.066-0.25,6.066-5.551,6.066-5.551C12,14.381,5.949,12.965,5.949,12.965z M17.312,14.073c0,0-0.669,1.959-5.051,1.959 c-3.505,0-5.388-1.164-5.607-1.959C6.654,14.073,12.566,15.128,17.312,14.073z M11.804,1.011c-6.195,0-10.826,5.022-10.826,11.217 s4.826,10.761,11.021,10.761S23.02,18.423,23.02,12.228C23.021,6.033,17.999,1.011,11.804,1.011z M12,21.354 c-5.273,0-9.381-3.886-9.381-9.159s3.942-9.548,9.215-9.548s9.548,4.275,9.548,9.548C21.381,17.467,17.273,21.354,12,21.354z  M15.108,11.603c0.795,0,1.439-0.879,1.439-1.962s-0.644-1.962-1.439-1.962s-1.439,0.879-1.439,1.962S14.313,11.603,15.108,11.603z"></path></svg></span>
                    <span data-testid="clip" data-icon="clip" className=""><svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" className="" version="1.1" x="0px" y="0px" enableBackground="new 0 0 24 24" ><path fill="#646464" d="M1.816,15.556v0.002c0,1.502,0.584,2.912,1.646,3.972s2.472,1.647,3.974,1.647 c1.501,0,2.91-0.584,3.972-1.645l9.547-9.548c0.769-0.768,1.147-1.767,1.058-2.817c-0.079-0.968-0.548-1.927-1.319-2.698 c-1.594-1.592-4.068-1.711-5.517-0.262l-7.916,7.915c-0.881,0.881-0.792,2.25,0.214,3.261c0.959,0.958,2.423,1.053,3.263,0.215 c0,0,3.817-3.818,5.511-5.512c0.28-0.28,0.267-0.722,0.053-0.936c-0.08-0.08-0.164-0.164-0.244-0.244 c-0.191-0.191-0.567-0.349-0.957,0.04c-1.699,1.699-5.506,5.506-5.506,5.506c-0.18,0.18-0.635,0.127-0.976-0.214 c-0.098-0.097-0.576-0.613-0.213-0.973l7.915-7.917c0.818-0.817,2.267-0.699,3.23,0.262c0.5,0.501,0.802,1.1,0.849,1.685 c0.051,0.573-0.156,1.111-0.589,1.543l-9.547,9.549c-0.756,0.757-1.761,1.171-2.829,1.171c-1.07,0-2.074-0.417-2.83-1.173 c-0.755-0.755-1.172-1.759-1.172-2.828l0,0c0-1.071,0.415-2.076,1.172-2.83c0,0,5.322-5.324,7.209-7.211 c0.157-0.157,0.264-0.579,0.028-0.814c-0.137-0.137-0.21-0.21-0.342-0.342c-0.2-0.2-0.553-0.263-0.834,0.018 c-1.895,1.895-7.205,7.207-7.205,7.207C2.4,12.645,1.816,14.056,1.816,15.556z"></path></svg></span>
                </div>
                <textarea name="y" id="y" cols="30" rows="1" placeholder="Escribe un mensaje aquí"></textarea>
                <span onClick={ () => setShowModalFlows(true) } data-testid="send" data-icon="send" className=""><svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" className="" version="1.1" x="0px" y="0px" enableBackground="new 0 0 24 24" ><path fill="#646464" d="M1.101,21.757L23.8,12.028L1.101,2.3l0.011,7.912l13.623,1.816L1.112,13.845 L1.101,21.757z"></path></svg></span>
            </div>
            {showModalFlows && <div className="modal-enviar-mensaje">
                <button onClick={ () => setShowModalFlows(false) } >Cerrar</button>
                <h3>Selecciona el mensaje</h3>
                <div className='cont-lista-mensajes-a-enviar'>
                    {flowList?.map(flow => {
                        return <div key={ flow.id } className='mensaje-flow-item' onClick={ () => enviarMensaje(flow.id, celular) } ><b>{flow.type}:</b> {flow.name}</div>
                    })}
                </div>
            </div>}
        </>;
    } else {
        divChats = <div className="empty-chat"><img src={ imagenBot } ></img></div>;
    }
    
    return(
        <div className="chats-der">
            { mostarModalBox &&
                <ModalBox close={ () => closeModalBox() } title={ 'CONTENIDO LISTA' }>
                    <ListView lista={ currentModal }/>
                </ModalBox>
            }
            { divChats }
        </div>
    );
}

export default ChatContainer;