import { Oval } from 'react-loader-spinner';

const LoadingOval = ({ show, text }) => {
  return <div className="loading-oval-text">
    <Oval
      visible={show}
      height="40"
      width="40"
      color="red"
      secondaryColor="var(-rosado)"
      ariaLabel="oval-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
    <span>{ text }</span>
  </div>
}

export { LoadingOval }