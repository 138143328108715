import Icons from "../../components/Icons"
import styles from '../../assets/css/preview-messages.module.css'

const PrevLista = ({ lista, cerrarlista }) => {
    return <div className={styles.prevList}>
        <div className={styles.headerList}>
            <h6>{lista.button}</h6>
            <button className={styles.btnCerrarList} onClick={cerrarlista}>
                <Icons req={'close_x'} fill='var(--gris-oscuro)' height='1.5em' />
            </button>
        </div>
        {
            lista?.sections?.map((section, index) => <div key={index} className={styles.listSection}>
                <h4 className={styles.listSectionTitle}>{section.title}</h4>
                {
                    section?.rows?.map((row, index) => <div key={index} className={styles.listRow}>
                        <p className={styles.listRowTitle}>{row.title}</p>
                        <p className={styles.listRowDescription}>{row.description}</p>
                    </div>)
                }
            </div>)
        }
    </div>
}

export default PrevLista