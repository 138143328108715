import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query'
import { userAuth } from "../api/fetchsApiQuerys";
import Spiner from "../components/Spiner";
import { useForm } from "react-hook-form";
import PhoneInput from 'react-phone-input-2';
import { customSwall } from "../components/CustomSwal";
import 'react-phone-input-2/lib/style.css';
import Icons from "../components/Icons";

function LoginForm({ handleForm }) {

    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const { register, handleSubmit, watch, formState: { errors } } = useForm()

    const { refetch, isFetching } = useQuery(["userData"], () => userAuth({ password: watch('password'), correo: watch('correo') }), {
        refetchOnWindowFocus: false,
        enabled: false, // disable this query from automatically running
        onSuccess: data => {
            try {
                if (data.ok) {
                    localStorage.setItem('userData', JSON.stringify(data.user))
                    navigate('/')
                } else {
                    customSwall.fire({
                        title: 'Error!',
                        text: 'El usuario o la contraseña estan incorrectos',
                        icon: 'error',
                        confirmButtonText: 'OK'
                    }).then(result => {
                        document.querySelector('form').reset();
                    });
                }
            } catch (error) {
                customSwall.fire({
                    title: 'Error!',
                    text: 'No se puede acceder al sistema en este momento, contacta a soporte',
                    icon: 'error',
                    confirmButtonText: 'OK'
                }).then(result => {
                    document.querySelector('form').reset();
                });
            }
        },
        onError: e => {
            console.error(e)
        }
    });

    const onSubmit = handleSubmit(data => {
        queryClient.removeQueries({ queryKey: ['userData'] })
        refetch()
    })

    return(
        <form className="form-login form-active" onSubmit={ onSubmit }>
            { isFetching && <Spiner/> }
            <h3 className="form-titulo">Ingresa tus datos</h3>
            <p className="text-opcion" onClick={ handleForm } >No estas registrado?</p>
            <div className="cont-item-form">
                <input type="email" placeholder="correo electrónico" { ...register('correo', {
                    required: {
                        value: true,
                        message: 'Ingresa el usuario'
                    },
                }) }/>
                <span>{ !!errors['correo'] && errors['correo'].message }</span>
            </div>
            <div className="cont-item-form">
                <input type={ passwordShown ? 'text' : 'password' } placeholder="contraseña" { ...register('password', {
                    required: {
                        value: true,
                        message: 'Se requiere la contraseña'
                    },
                }) }/>
                <button type="button" className="btn-show-password" onClick={togglePasswordVisiblity}>
                    <Icons req={ 'eye' }/>
                </button>
                <span>{ !!errors['password'] && errors['password'].message }</span>
            </div>
            <button type="submit">Ingresar</button>
            <p className="text-opcion">Olvidé mi contraseña</p>
        </form>
    );
}

function RegForm({ handleForm }) {

    const [state, setState] = useState(0);
    //console.log(state)

    /*let emailInput;
    let passwordInput;

    function sendInfo(e) {

        if (passwordInput !== '' && emailInput !== '') {

        }
        e.preventDefault();
        let form = new FormData(document.querySelector('.form-login'));
        form.append('nonoo', 'iaikaka')
        console.log(form.get('correo'))
        console.log(emailInput.value)
    }*/

    return(
        <form className="form-reg">
            <h3 className="form-titulo">Ingresa tus datos</h3>
            <p className="text-opcion" onClick={ handleForm } >Ya estas registrado?</p>
            <div className="cont-item-form">
                <input type="text" name="nombre" placeholder="nombre" required/>
            </div>
            <div className="cont-item-form">
                <input type="text" name="apellido" placeholder="apellido" required/>
            </div>
            <div className="cont-item-form">
                <input type="email" name="correo" placeholder="correo electrónico" required/>
            </div>
            <div className="cont-item-form">
                <PhoneInput country={'ec'} value={state.phone} onChange={phone => setState({ phone })} />
            </div>
            <div className="cont-item-form">
                <input type="password" name="password" placeholder="contraseña" required/>
                <p className="pass-info">Mínimo 8 caracteres</p>
            </div>
            <div className="cont-item-form">
                <input type="password" name="re-password" placeholder="repita contraseña" required/>
                <p className="pass-info">Repite la contraseña</p>
            </div>
            <button type="submit">Registrarse</button>
        </form>
    );
}

export default function LogReg({ regLogin }) {

    const [ formActive, setFormActive ] = useState(true);

    function SendInfo(form) {
        regLogin(form)
    }

    function alternarForm() {
        setFormActive(!formActive);
    }
    
    return(
        <div className="body-login">
            <div className="columna-completa cont-login">
                <div className="formularios">
                    { formActive ? <LoginForm handleForm={ () => alternarForm() } sendInfo={ (form) => SendInfo(form) } /> : <RegForm handleForm={ () => alternarForm() } /> }
                </div>
                <div className="banner">
                    <div className="area" >
                        <ul className="circles">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div >
                </div>
            </div>
        </div>
    );
}